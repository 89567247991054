import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { message, Modal } from "antd";
import { RiCheckboxCircleLine } from "react-icons/ri";
// Login Redux States
import {
  apiError,
  loginUserSuccessful,
  OTPRequestSuccessful,
  resendOTPSuccessful,
  showResend
} from "./loginSlice";

// AUTH related methods
import {
  postLogin,
  simplePost,
  postAdd,
  postLogout,
} from "../../../helpers/Helper";

import loginData from "./loginData.json"


//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload }) {
  const LoginData = loginData;
  try {
    yield put(loginUserSuccessful(LoginData));
    localStorage.setItem("access_token", LoginData.access);
    payload.history.push("/");

      


    // const response = yield call(postLogin, "/users/login/", payload.loginData);
    // console.log("response", response, response.status);
    // if (response.status === 200) {
    //   localStorage.setItem("access_token", response.data.tokens?.access);
    //   localStorage.setItem("refresh_token", response.data.tokens?.refresh);
    //   localStorage.setItem("username", response.data.username);
    //   localStorage.setItem("User_full_name", response.data.full_name);
    //   localStorage.setItem("email", response.data.email);
    //   localStorage.setItem("phone", response.data.phone);
    //   localStorage.setItem("device_uuid", payload.loginData.device_uuid);

    //   yield put(loginUserSuccessful(response));
    //   // message.success({
    //   //   content: (
    //   //     <div>
    //   //       <p className="hp-p1-body hp-text-color-black-80">
    //   //         Login successfully.!
    //   //       </p>
    //   //     </div>
    //   //   ),
    //   //   title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
    //   //   icon: (
    //   //     <span className="remix-icon">
    //   //       <RiCheckboxCircleLine />
    //   //     </span>
    //   //   ),
    //   //   duration: 1,
    //   // });
    //   message.success({
    //     content: "Login successfully!",
    //     icon: <RiCheckboxCircleLine className="remix-icon" />,
    //   });
    //   payload.history.push("/");
    // } else {
    //   alert("error");
    // }
  } catch (error) {
    console.log("error", error);

    yield put(apiError(error.data));
  }
}
function* OTPRequest({ payload }) {
  try {
    const response = yield call(simplePost, "/users/otprequest/", payload);
    console.log("response", response, response.status);
    if (response.status === 200) {
      // message.success({
      //   content: "OPT:" + response.data.otp,
      //   icon: <RiCheckboxCircleLine className="remix-icon" />,
      //   duration: 5,
      // });
      yield put(OTPRequestSuccessful(true));
    } else {
      alert("error");
      yield put(OTPRequestSuccessful(false));
    }
  } catch (error) {
    yield put(apiError(error?.data));
  }
}

function* logoutUser({ payload }) {
  localStorage.clear();
     
  try {
    if (payload.data.refresh != null) {
      const response = yield call(postLogout, "/users/logout/", payload.data);
      console.log("response", response, response.status);
      payload.history.push("/login");
    } else {
      payload.history.push("/login");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}


function* ResendOTP({ payload }) {
  console.log("payload", payload);
  try {
    const response = yield call(simplePost, "/users/resendotp/", payload);
    console.log("response", response, response.status);
    if (response.status === 200) {
      // message.success({
      //   content: "OPT:" + response.data.otp,
      //   icon: <RiCheckboxCircleLine className="remix-icon" />,
      //   duration: 5,
      // });
      yield put(resendOTPSuccessful(true));
      yield put(showResend(false));
    } else {
      alert("error");
      yield put(resendOTPSuccessful(false));
    }
  } catch (error) {
    yield put(apiError(error?.data));
  }
}

export function* watchUserLogin() {
  yield takeEvery("login/checkLogin", loginUser);
}
export function* watchGetOTPRequest() {
  yield takeEvery("login/getOTPRequest", OTPRequest);
}

export function* watchUserLogout() {
  yield takeEvery("login/logoutUser", logoutUser);
}

export function* watchGetResendOTP() {
  yield takeEvery("login/getResendOTP", ResendOTP);
}

function* loginSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchGetOTPRequest),
    fork(watchUserLogout),
    fork(watchGetResendOTP),
  ]);
}

export default loginSaga;
