import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const vendorSlice = createSlice({
  name: "vendors",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    formSubmitLoading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    purchaseHistoryList: [],
    saleHistoryList: [],
    repairAmountList: [],
    reEstimationList: [],
    purchaseList: [],
    purchaseEnquiryList: [],
    purchaseData: {
      hypothecationisinternal: false,
      loanstatus: false,
    },
    purchaseRejectData: {},
    imageLooading: false,
    checkApprove: false,
    vehicleVersion: {},
    customerByIdList: {},
    counterData: {},
    vehicleData: {},
    RejectMode: false,
    purchaseParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    vendorData: {},
    model: false,
    rejectReasonObjData: {},
  },
  reducers: {
    getPurchaseList: (state, action) => {
      if (state.purchaseParams?.page == 1) {
        return {
          ...state,
          loading: true,
          purchaseList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    purchaseListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.BookingData.count / state.purchaseParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.BookingData.count,
        purchaseList: [
          ...state.purchaseList,
          ...action.payload.BookingData.results,
        ],
        loading: false,
        purchaseParams: {
          ...state.purchaseParams,
          no_of_pages: noofpages,
        },
      };
    },
    clearPurchaseList: (state, action) => {
      return {
        ...state,
        loading: true,
        purchaseList: [],
      };
    },

    // Purchase Vehicle Historty

    getPurchaseHistory: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
        purchaseHistoryList: [],
      };
    },

    purchaseHistoryListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.purchaseParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        purchaseHistoryList: action.payload.response.results,
        loading: false,
        formSubmitLoading: false,
        purchaseParams: {
          ...state.purchaseParams,
          no_of_pages: noofpages,
        },
      };
    },

    //  Sale Vehicle Historty

    getSaleHistory: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
        saleHistoryList: [],
      };
    },

    saleHistoryListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.purchaseParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        saleHistoryList: action.payload.response.results,
        loading: false,
        purchaseParams: {
          ...state.purchaseParams,
          no_of_pages: noofpages,
        },
      };
    },

    // Repair Amount

    getRepairAmount: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
        repairAmountList: [],
      };
    },

    repairAmountListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.purchaseParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        repairAmountList: action.payload.response.results,
        loading: false,
        purchaseParams: {
          ...state.purchaseParams,
          no_of_pages: noofpages,
        },
      };
    },

    // Re Estimation

    getReEstimation: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
        reEstimationList: [],
      };
    },

    reEstimationListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.purchaseParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        reEstimationList: action.payload.response.results,
        loading: false,
        purchaseParams: {
          ...state.purchaseParams,
          no_of_pages: noofpages,
        },
      };
    },

    addPurchase: (state, action) => {
      return {
        ...state,
        formSubmitLoading: true,
      };
    },
    addPurchaseSuccessful: (state, action) => {
      return {
        ...state,
        formSubmitLoading: false,
      };
    },
    purchaseClear: (state, action) => {
      return {
        ...state,
        loading: false,
        purchaseData: {},
        vehicleVersion: {},
      };
    },
    getPurchaseDoc: (state, action) => {
      return {
        ...state,
        loading: true,
        purchaseData: {},
        vehicleVersion: {},
      };
    },
    purchaseDocSuccessful: (state, action) => {
      return {
        ...state,
        purchaseData: {
          ...action.payload,
          dealer_id: action.payload.dealer?.id,
          financierbank_id: action.payload.financierbank?.id,
          tokendate:
            action.payload.tokendate == null
              ? null
              : moment(action.payload?.tokendate),
          insurancecompany_id: action.payload.insurancecompany?.id,
          insuranceexpdate:
            action.payload.insuranceexpdate == null
              ? null
              : moment(action.payload?.insuranceexpdate),
          customer_id: action.payload.customer?.id,
          internalfinancier_id: action.payload.internalfinancier?.id,
          counter_id: action.payload.counter?.id,
          vehicle_id: action.payload.vehicle?.id,
          color_id: action.payload.color?.id,
          mcinternal_id: action.payload.mcinternal?.id,
          addressproof1_id: action.payload.addressproof1?.id,
          addressproof2_id: action.payload.addressproof2?.id,
          externalmcbank_id: action.payload.externalmcbank?.id,
          mcinternal_id: action.payload.mcinternal?.id,
        },
        loading: false,
      };
    },

    GetCounterByID: (state, action) => {
      return {
        ...state,
        loading: true,
        counterData: {},
      };
    },
    counterByIDSuccessful: (state, action) => {
      return {
        ...state,
        counterData: action.payload.response,
        loading: false,
      };
    },
    getVehicleVersion: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleVersion: action.payload,
      };
    },
    getVehicleData: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleData: {},
      };
    },
    vehicleDataSuccessful: (state, action) => {
      return {
        ...state,
        vehicleData: action.payload.response,
        loading: false,
      };
    },

    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "partytype":
          if (action.payload.value == 4 || action.payload.value == 5) {
            return {
              ...state,
              purchaseData: {
                counter_id: state.purchaseData.counter_id,
                mc_internal_type: 3,
                [action.payload.key]: action.payload.value,
              },
            };
          } else {
            return {
              ...state,
              purchaseData: {
                counter_id: state.purchaseData.counter_id,
                [action.payload.key]: action.payload.value,
              },
            };
          }
        case "hypothecation":
          return {
            ...state,
            purchaseData: {
              ...state.purchaseData,
              [action.payload.key]: action.payload.value,
              hypothecationisinternal: false,
              internalfinancier_id: null,
              externalfinanciername: null,
              financierbank_id: null,
              financieraccountnumber: null,
              financierifsccode: null,
              loanstatus: false,
              loanamount: null,
              loannumber: null,
            },
          };
        case "loanstatus":
          return {
            ...state,
            purchaseData: {
              ...state.purchaseData,
              [action.payload.key]: action.payload.value,
              hypothecationisinternal: false,
              internalfinancier_id: null,
              externalfinanciername: null,
              financierbank_id: null,
              financieraccountnumber: null,
              financierifsccode: null,
              loanamount: null,
            },
          };

        case "hypothecationisinternal":
          return {
            ...state,
            purchaseData: {
              ...state.purchaseData,
              [action.payload.key]: action.payload.value,
              externalfinanciername: null,
              financierbank_id: null,
              financieraccountnumber: null,
              financierifsccode: null,
              internalfinancier_id: null,
            },
          };

          break;

        case "tokenstatus":
          return {
            ...state,
            purchaseData: {
              ...state.purchaseData,
              [action.payload.key]: action.payload.value,
              tokennumber: null,
              tokendate: null,
            },
          };

        case "insurancestatus":
          return {
            ...state,
            purchaseData: {
              ...state.purchaseData,
              [action.payload.key]: action.payload.value,
              insurancecompany_id: null,
              insurancenumber: null,
              insuranceexpdate: null,
            },
          };

        case "iscustomcolor":
          return {
            ...state,
            purchaseData: {
              ...state.purchaseData,
              [action.payload.key]: action.payload.value,
              color_id: null,
              customcolor: null,
            },
          };

        case "mc_internal_type":
          if (action.payload.value == 1) {
            return {
              ...state,
              purchaseData: {
                ...state.purchaseData,
                [action.payload.key]: action.payload.value,
              },
            };
          } else if (action.payload.value == 2) {
            return {
              ...state,
              purchaseData: {
                ...state.purchaseData,
                [action.payload.key]: action.payload.value,
                externalmcname: null,
                externalmcmobile: null,
                externalmcbank_id: null,
                externalmcbankacno: null,
                externalmcifsccode: null,
              },
            };
          } else if (action.payload.value == 3) {
            return {
              ...state,
              purchaseData: {
                ...state.purchaseData,
                [action.payload.key]: action.payload.value,
                mcamount: null,
                externalmcname: null,
                externalmcmobile: null,
                externalmcbank_id: null,
                externalmcbankacno: null,
                externalmcifsccode: null,
                mcinternal_id: null,
              },
            };
          }

        default:
          return {
            ...state,
            purchaseData: {
              ...state.purchaseData,
              [action.payload.key]: action.payload.value,
            },
          };
      }
    },
    getPurchasesImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        imageLooading: true,
        purchaseData: {
          ...state.purchaseData,
          [action.payload.imageType]: null,
        },
      };
    },
    purchasesImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        imageLooading: false,
        purchaseData: {
          ...state.purchaseData,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },
    getFormulaExecuter: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    FormulaExecuterSuccessful: (state, action, code) => {
      if (action.payload.formulaCode == "ESTIMATIONREPAIR") {
        return {
          ...state,
          loading: false,
          purchaseData: {
            ...state.purchaseData,
            totalrepairamount: parseFloat(action.payload.response).toFixed(2),
          },
        };
      } else if (action.payload.formulaCode == "PURCUSTOMERPAY") {
        return {
          ...state,
          loading: false,
          purchaseData: {
            ...state.purchaseData,
            customerpaybleamount: parseFloat(action.payload.response).toFixed(
              2
            ),
            customercreditamount: 0,
            customerbankamount: 0,
            customercashamount: parseFloat(action.payload.response).toFixed(2),
          },
        };
      } else if (action.payload.formulaCode == "CASHAMOUNT") {
        return {
          ...state,
          loading: false,
          purchaseData: {
            ...state.purchaseData,
            customercashamount: parseFloat(action.payload.response).toFixed(2),
          },
        };
      } else if (action.payload.formulaCode == "PURTOTALPAY") {
        return {
          ...state,
          loading: false,
          purchaseData: {
            ...state.purchaseData,
            total: parseFloat(action.payload.response).toFixed(2),
          },
        };
      }
    },
    purchaseApproval: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getPurchaseCheckApprove: (state) => {
      return {
        ...state,
        loading: true,
        checkApprove: false,
      };
    },
    purchasesCheckApproveSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        checkApprove: action.payload,
      };
    },
    isRejectModelVisible: (state, action) => {
      return {
        ...state,
        RejectMode: action.payload,
        purchaseRejectData: {},
      };
    },
    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        purchaseRejectData: {
          ...state.purchaseRejectData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getPurchaseEnquiry: (state) => {
      return {
        ...state,
        purchaseEnquiryList: [],
        loading: true,
      };
    },
    purchaseEnquirySuccessful: (state, action) => {
      return {
        ...state,
        purchaseEnquiryList: action.payload.results,
        loading: false,
      };
    },
    purchaseDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    setPurchaseParams: (state, action) => {
      return {
        ...state,
        purchaseParams: action.payload,
      };
    },

    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            purchaseParams: {
              ...state.purchaseParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            purchaseParams: {
              ...state.purchaseParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            purchaseParams: {
              ...state.purchaseParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        default:
          return {
            ...state,
            purchaseParams: {
              ...state.purchaseParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   purchaseParams: {
      //     ...state.purchaseParams,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    getCustomerById: (state, action) => {
      return {
        ...state,
        loading: true,
        customerByIdList: action.payload,
      };
    },
    insuranceNumberValidate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getRejectReasonData: (state, action) => {
      return {
        ...state,
        loading: true,
        rejectReasonObjData: action.payload,
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          vendorData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    dealerAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    dealerAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        vendorData: {},
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getPurchaseList,
  purchaseListSuccessful,
  clearPurchaseList,
  addPurchase,
  addPurchaseSuccessful,
  purchaseClear,
  getPurchaseDoc,
  purchaseDocSuccessful,
  getVehicleVersion,
  InputChangeValue,
  GetCounterByID,
  counterByIDSuccessful,
  getVehicleData,
  vehicleDataSuccessful,
  getPurchasesImageUpload,
  purchasesImageUploadSuccessful,
  getFormulaExecuter,
  FormulaExecuterSuccessful,
  purchaseApproval,
  getPurchaseCheckApprove,
  purchasesCheckApproveSuccessful,
  isRejectModelVisible,
  rejectModelInputChangeValue,
  getPurchaseEnquiry,
  purchaseEnquirySuccessful,
  setPurchaseParams,
  apiError,
  getPurchaseHistory,
  purchaseHistoryListSuccessful,
  getSaleHistory,
  saleHistoryListSuccessful,
  purchaseDelete,
  isDrawerVisible,
  FilterInputChangeValue,
  getRepairAmount,
  repairAmountListSuccessful,
  getReEstimation,
  reEstimationListSuccessful,
  getCustomerById,
  insuranceNumberValidate,
  getRejectReasonData,
  isModelVisible,
  dealerAdd,
  dealerAddSuccessful
} = vendorSlice.actions;

export default vendorSlice.reducer;
