import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const receiptSlice = createSlice({
  name: "payment",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    listCount: 0,
    paymentParams: {
        no_of_pages: 0,
        page_size: 10,
        page: 1,
        search: "",
        currentSort: "code",
        sortOrder: "-",
      },
    receiptData: {},
  },
  reducers: {
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
        return {
          ...state,
          paymentParams: {
            ...state.paymentParams,
            [action.payload.key]: action.payload.value,
          },
        };
      },
      InputChangeValue: (state, action) => {
        return {
            ...state,
            receiptData: {
              ...state.receiptData,
              [action.payload.key]: action.payload.value,
            },
          };
      },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  isDrawerVisible,
  FilterInputChangeValue,
  InputChangeValue,
  apiError,
} = receiptSlice.actions;

export default receiptSlice.reducer;
