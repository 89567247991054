import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  ImportModelsListSuccessful,
  ExportModelsListSuccessful,
  uploadImportDataSuccessful,
  uploadImportType2DataSuccessful,
  submitImportDataSuccessful,
  loadingStatus,
} from "./importExportSlice";

// AUTH related methods
import {
  postAdd,
  postFormData,
  getList,
  postDownloadFile,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
function* ImportModelsList() {
  try {
    const response = yield call(getList, "/reports/generic_import_models/", {});
    yield put(ImportModelsListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* ExportModelsList() {
  try {
    const response = yield call(getList, "/reports/generic_export_models/", {});
    yield put(ExportModelsListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* UploadImportData({ payload }) {
  try {
    const response = yield call(
      postFormData,
      "/reports/generic_import/dryrun",
      payload
    );
    yield put(uploadImportDataSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* UploadImportType2Data({ payload }) {
  try {
    const response = yield call(
      postFormData,
      "/reports/generic_import_2/dryrun/",
      payload
    );
    yield put(uploadImportType2DataSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* SubmitImportData({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/reports/generic_import/process",
      payload
    );
    yield put(submitImportDataSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* SubmitImportType2Data({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/reports/generic_import_2/process/",
      payload
    );
    yield put(submitImportDataSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* DownloadExportData({ payload }) {
  try {
    yield call(postDownloadFile, "/reports/generic_export/", payload, {});
    Modal.success({
      icon: (
        <span className="remix-icon">
          <RiCheckboxCircleLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Your file downloaded successfully.!
          </p>
        </div>
      ),
    });
    yield put(loadingStatus(false));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchGetImportModelsList() {
  yield takeEvery("importExport/getImportModelsList", ImportModelsList);
}
export function* watchGetExportModelsList() {
  yield takeEvery("importExport/getExportModelsList", ExportModelsList);
}
export function* watchUploadImportData() {
  yield takeEvery("importExport/uploadImportData", UploadImportData);
}
export function* watchUploadImportType2Data() {
  yield takeEvery("importExport/uploadImportType2Data", UploadImportType2Data);
}
export function* watchSubmitImportData() {
  yield takeEvery("importExport/submitImportData", SubmitImportData);
}
export function* watchSubmitImportType2Data() {
  yield takeEvery("importExport/submitImportType2Data", SubmitImportType2Data);
}
export function* watchDownloadExportData() {
  yield takeEvery("importExport/downloadExportData", DownloadExportData);
}

function* ImportExportSaga() {
  yield all([
    fork(watchGetImportModelsList),
    fork(watchGetExportModelsList),
    fork(watchUploadImportData),
    fork(watchUploadImportType2Data),
    fork(watchSubmitImportData),
    fork(watchSubmitImportType2Data),
    fork(watchDownloadExportData),
  ]);
}

export default ImportExportSaga;
