import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { MENU, MENU_ADD, MENU_ROW_DATA, MENU_DELETE } from "./actionTypes";
import {
  apiError,
  getMenus,
  MenuListSuccessful,
  MenuDataSuccessful,
  MenuAddSuccessful,
} from "./actions";

// AUTH related methods
import {
  postAdd,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import Swal from "sweetalert2";

function* getMenu() {
  try {
    const response = yield call(getList, "/system/menu/", {});
    yield put(MenuListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MenuRowData({ payload: { MenuId } }) {
  try {
    const response = yield call(getList, "/system/menu/" + MenuId, {});
    yield put(MenuDataSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
//If user is login then dispatch redux action's are directly from here.
function* AddMenu({ payload: { data } }) {
  try {
    if (data.id == null) {
      const response = yield call(postAdd, "/system/menu/", data);
      if (response.status === 201) {
        Swal.fire({
          title: "Your Menu Added Successfully.!",
          icon: "success",
          timer: 1500,
        });
        yield put(getMenus());
      } else {
        Swal.fire({
          title: "Sorry! Unable to added Menu. Please try again!",
          icon: "error",
        });
      }
      yield put(MenuAddSuccessful(null));
    } else {
      const response = yield call(postEdit, "/system/menu/" + data.id, data);
      if (response.status === 200) {
        Swal.fire({
          title: "Your Menu Edited Successfully.!",
          icon: "success",
          timer: 1500,
        });
        yield put(getMenus());
      } else {
        Swal.fire({
          title: "Sorry! Unable to edit Menud. Please try again!",
          icon: "error",
        });
      }
      yield put(MenuAddSuccessful(null));
    }
  } catch (error) {
    Swal.fire({
      title: "Sorry! Permission Denied!",
      icon: "error",
    });
    //yield put(apiError(error));
  }
}

//If user is login then dispatch redux action's are directly from here.
function* DeleteMenu({ payload: { MenuId } }) {
  try {
    const response = yield call(postDelete, "/system/menu/" + MenuId);

    if (response.status === 204) {
      Swal.fire({
        title: " Your Menu has been deleted!",
        icon: "success",
        timer: 1500,
      });
      yield put(getMenus());
    } else {
      Swal.fire({
        title: "Sorry! Your Menu has been not deleted!",
        icon: "error",
      });
    }
  } catch (error) {
    Swal.fire({
      title: "Sorry! Permission Denied!",
      icon: "error",
    });

    yield put(apiError(error));
  }
}

export function* watchgetMenus() {
  yield takeEvery(MENU, getMenu);
}
export function* watchMenuAdd() {
  yield takeEvery(MENU_ADD, AddMenu);
}
export function* watchMenuRowData() {
  yield takeEvery(MENU_ROW_DATA, MenuRowData);
}

export function* watchMenuDelete() {
  yield takeEvery(MENU_DELETE, DeleteMenu);
}

function* MenusSaga() {
  yield all([
    fork(watchgetMenus),
    fork(watchMenuAdd),
    fork(watchMenuRowData),
    fork(watchMenuDelete),
  ]);
}

export default MenusSaga;
