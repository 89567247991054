import { createSlice } from "@reduxjs/toolkit";
export const insuranceSlice = createSlice({
  name: "insurance",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    insurancelist: [],
    model: false,
    error_msg: null,
    listCount: 0,
    rowdata: {},
    insuranceParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getInsurance: (state) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
      };
    },
    insuranceListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.insuranceParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        insurancelist: action.payload.response.results,
        loading: false,
        insuranceParams: {
          ...state.insuranceParams,
          no_of_pages: noofpages,
        },
      };
    },
    insuranceAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    insuranceAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    insuranceEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    insuranceEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    insuranceDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetInsuranceParams: (state, action) => {
      return {
        ...state,
        insuranceParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getInsurance,
  insuranceListSuccessful,
  insuranceAdd,
  insuranceAddSuccessful,
  insuranceEdit,
  insuranceEditSuccessful,
  insuranceDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetInsuranceParams
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
