import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  MENU_ITEMS,
  MENU_ITEMS_ADD,
  MENU_ITEMS_ROW_DATA,
  MENU_ITEMS_DELETE,
} from "./actionTypes";
import {
  apiError,
  getMenuItems,
  MenuItemsListSuccessful,
  MenuItemRowDataSuccessful,
  MenuItemAddSuccessful,
} from "./actions";

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import Swal from "sweetalert2";

function* getMenuItem({ payload: { data } }) {
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/system/menuitem/", params);
    yield put(MenuItemsListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MenuItemRowData({ payload: { MenuItemID } }) {
  try {
    const response = yield call(getList, "/system/menuitem/" + MenuItemID, {});
    yield put(
      MenuItemRowDataSuccessful({
        response: {
          ...response,
          MenuLabel: response.menu ? response.menu.name : "",
          menu_id: response.menu ? response.menu.id : "",
        },
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}
//If user is login then dispatch redux action's are directly from here.
function* AddMenuItem({ payload: { data } }) {
  try {
    if (data.id == null) {
      const response = yield call(postAdd, "/system/menuitem/", data);
      if (response.status === 201) {
        Swal.fire({
          title: "Your Menu Item Added Successfully.!",
          icon: "success",
          timer: 1500,
        });
        yield put(getMenuItems());
      } else {
        Swal.fire({
          title: "Sorry! Unable to added Menu Item. Please try again!",
          icon: "error",
        });
      }
      yield put(MenuItemAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/system/menuitem/" + data.id,
        data
      );
      if (response.status === 200) {
        Swal.fire({
          title: "YourMenu Item Edited Successfully.!",
          icon: "success",
          timer: 1500,
        });
        yield put(getMenuItems());
      } else {
        Swal.fire({
          title: "Sorry! Unable to edit Menu Item. Please try again!",
          icon: "error",
        });
      }
      yield put(MenuItemAddSuccessful(null));
    }
  } catch (error) {
    Swal.fire({
      title: "Sorry! Permission Denied!",
      icon: "error",
    });
    //yield put(apiError(error));
  }
}

//If user is login then dispatch redux action's are directly from here.
function* DeleteMenuItem({ payload: { MenuItemID } }) {
  try {
    const response = yield call(postDelete, "/system/menuitem/" + MenuItemID);

    if (response.status === 204) {
      Swal.fire({
        title: " Your Menu Item has been deleted!",
        icon: "success",
        timer: 1500,
      });
      yield put(getMenuItems());
    } else {
      Swal.fire({
        title: "Sorry! Your Menu Item has been not deleted!",
        icon: "error",
      });
    }
  } catch (error) {
    Swal.fire({
      title: "Sorry! Permission Denied!",
      icon: "error",
    });

    yield put(apiError(error));
  }
}

export function* watchgetMenuItems() {
  yield takeEvery(MENU_ITEMS, getMenuItem);
}
export function* watchMenuItemsAdd() {
  yield takeEvery(MENU_ITEMS_ADD, AddMenuItem);
}
export function* watchMenuItemsRowData() {
  yield takeEvery(MENU_ITEMS_ROW_DATA, MenuItemRowData);
}

export function* watchMenuItemsDelete() {
  yield takeEvery(MENU_ITEMS_DELETE, DeleteMenuItem);
}

function* MenuItemsSaga() {
  yield all([
    fork(watchgetMenuItems),
    fork(watchMenuItemsAdd),
    fork(watchMenuItemsRowData),
    fork(watchMenuItemsDelete),
  ]);
}

export default MenuItemsSaga;
