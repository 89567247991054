import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getMechanics,
  mechanicsListSuccessful,
  // countryAddSuccessful,
  isModelVisible,
} from "./mechanicsSlice";

// AUTH related methods
import {
  postAdd,
  getParamsList,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import Swal from "sweetalert2";

function* MechanicsGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mechanics/", data);
    yield put(mechanicsListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* AddMechanics({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/masters/mechanics/", payload);
      if (response.status === 201) {
        Swal.fire({
          title: "Your Mechanics Added Successfully.!",
          icon: "success",
          timer: 1500,
        });
        yield put(isModelVisible(false));
        yield put(getMechanics({ page: 1, search: "", page_size: 10 }));
      } else {
        Swal.fire({
          title: "Sorry! Unable to added Country. Please try again!",
          icon: "error",
        });
      }
      // yield put(countryAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/masters/mechanics/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Swal.fire({
          title: "Your Mechanics Edited Successfully.!",
          icon: "success",
          timer: 1500,
        });
        yield put(getMechanics({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Swal.fire({
          title: "Sorry! Unable to edit Mechanics. Please try again!",
          icon: "error",
        });
      }
      // yield put(countryAddSuccessful(null));
    }
  } catch (error) {
    Swal.fire({
      title: "Sorry! Getting from server side issue!",
      icon: "error",
    });
    yield put(apiError(error));
  }
}

function* DeleteMechanic({ payload }) {
  try {
    const response = yield call(postDelete, "/masters/mechanics/" + payload);

    if (response.status === 204) {
      Swal.fire({
        title: " Your Mechanics has been deleted!",
        icon: "success",
        timer: 1500,
      });
      yield put(getMechanics({ page: 1, search: "", page_size: 10 }));
    } else {
      Swal.fire({
        title: "Sorry! Your Mechanics has been not deleted!",
        icon: "error",
      });
    }
  } catch (error) {
    Swal.fire({
      title: "Sorry! Getting from server side issue!",
      icon: "error",
    });

    yield put(apiError(error));
  }
}

export function* watchgetMechanics() {
  yield takeEvery("mechanics/getMechanics", MechanicsGet);
}
export function* watchMechanicsAdd() {
  yield takeEvery("mechanics/mechanicAdd", AddMechanics);
}

export function* watchMechanicsDelete() {
  yield takeEvery("mechanics/mechanicDelete", DeleteMechanic);
}

function* MechanicsSaga() {
  yield all([
    fork(watchgetMechanics),
    fork(watchMechanicsAdd),
    fork(watchMechanicsDelete),
  ]);
}

export default MechanicsSaga;
