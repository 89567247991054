import { createSlice } from "@reduxjs/toolkit";

export const clustersSlice = createSlice({
  name: "clusters",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    clusterslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    clustersParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getClusters: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    clustersListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.clustersParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        clusterslist: action.payload.response.results,
        loading: false,
        clustersParams: {
          ...state.clustersParams,
          no_of_pages: noofpages,
        },
      };
    },
    clusterAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    clustersEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          division_id: action.payload.division.id,
        },
      };
    },
    clusterDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetClustersParams: (state, action) => {
      return {
        ...state,
        clustersParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getClusters,
  clustersListSuccessful,
  clusterAdd,
  clustersEdit,
  clusterDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetClustersParams
} = clustersSlice.actions;

export default clustersSlice.reducer;
