import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  MasterMenuItemsSuccessful,
  sideMenuItemsSuccessful,
  ReportsMenuItemsSuccessful,
  userPermissionsSuccessful,
  QuickMenuItemsSuccessful,
  ImportMenuItemsSuccessful,
  profileMenuItemsSuccessful,
  ExportMenuItemsSuccessful,
  systemFormulasSuccessful,
} from "./systemSlice";

// AUTH related methods
import { getList, getParamsList } from "../../helpers/Helper";
import sideMenu from "./sideMenu.json";
import masterMenu from "./masterMenu.json";
import profileMenu from "./profileMenu.json";
import userPermission from "./userPermission.json";
import quickMenu from "./quickMenu.json";
import reportsData from  "./reportsData.json";


function* MasterMenuItems() {
  const MasterMenu = masterMenu;
  try {
    // const response = yield call(getList, "/system/usermenu/MENU0003", {});
    yield put(MasterMenuItemsSuccessful(MasterMenu));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* SideMenuItems() {
  const SideMenu = sideMenu;
  try {
    // const response = yield call(getList, "/system/usermenu/MENU0004", {});
    yield put(sideMenuItemsSuccessful(SideMenu));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* ReportsMenuItems() {
  const ReportsData = reportsData
  try {
    // const response = yield call(getList, "/system/usermenu/MENU0005", {});
    yield put(ReportsMenuItemsSuccessful(ReportsData));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* UserPermissions() {
  const UserPermission = userPermission;
  try {
    // const response = yield call(getList, "/users/userpermissions/", {});
    yield put(userPermissionsSuccessful(UserPermission));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* QuickMenuItems() {
  const QuickMenu = quickMenu;
  try {
    // const response = yield call(getList, "/system/usermenu/MENU0006", {});
    yield put(QuickMenuItemsSuccessful(QuickMenu));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* ImportMenuItems() {
  try {
    const response = yield call(getList, "/system/usermenu/MENU0007", {});
    yield put(ImportMenuItemsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* ProfileMenuItems() {
  const ProfileMenu = profileMenu
  try {
    // const response = yield call(getList, "/system/usermenu/MENU0008", {});
    yield put(profileMenuItemsSuccessful(ProfileMenu));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* ExportMenuItems() {
  try {
    const response = yield call(getList, "/system/usermenu/MENU0009", {});
    yield put(ExportMenuItemsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* SystemFormulas() {
  try {
    const response = yield call(getList, "/system/allformuls/", {});
    yield put(systemFormulasSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchgetMasterMenuItems() {
  yield takeEvery("system/getMasterMenuItems", MasterMenuItems);
}
export function* watchGetSideMenuItems() {
  yield takeEvery("system/getSideMenuItems", SideMenuItems);
}
export function* watchGetReportsMenuItems() {
  yield takeEvery("system/getReportsMenuItems", ReportsMenuItems);
}
export function* watchGetUserPermissions() {
  yield takeEvery("system/getUserPermissions", UserPermissions);
}
export function* watchGetQuickMenuItems() {
  yield takeEvery("system/getQuickMenuItems", QuickMenuItems);
}
export function* watchGetImportMenuItems() {
  yield takeEvery("system/getImportMenuItems", ImportMenuItems);
}
export function* watchGetProfileMenuItems() {
  yield takeEvery("system/getProfileMenuItems", ProfileMenuItems);
}
export function* watchGetExportMenuItems() {
  yield takeEvery("system/getExportMenuItems", ExportMenuItems);
}
export function* watchGetSystemFormulas() {
  yield takeEvery("system/getSystemFormulas", SystemFormulas);
}

function* menuItemSaga() {
  yield all([
    fork(watchgetMasterMenuItems),
    fork(watchGetSideMenuItems),
    fork(watchGetReportsMenuItems),
    fork(watchGetUserPermissions),
    fork(watchGetQuickMenuItems),
    fork(watchGetImportMenuItems),
    fork(watchGetProfileMenuItems),
    fork(watchGetExportMenuItems),
    fork(watchGetSystemFormulas),
  ]);
}

export default menuItemSaga;
