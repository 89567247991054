const data = [
    "24-hours",
    "4k",
    "a-b",
    "account-box",
    "account-circle",
    "account-circle",
    "account-circle",
    "account-circle",
    "account-circle",
    "account-circle",
    "account-circle",
    "account-pin-box",
   
]

export default data