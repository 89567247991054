import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const receiptSlice = createSlice({
  name: "receipt",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    listCount: 0,
    receiptParams: {
        no_of_pages: 0,
        page_size: 10,
        page: 1,
        search: "",
        currentSort: "code",
        sortOrder: "-",
      },
    receiptData: {},
    receiptList: [],
  },
  reducers: {
    getReceiptList: (state, action) => {
      if (state.receiptParams.page == 1) {
        return {
          ...state,
          loading: true,
          receiptList: [],
          receiptParams: {
            ...state.receiptParams,
            // page_size: 10,
            // page: 1,
            ...action.payload 
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    receiptListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.receiptParams?.page_size
      );
      console.log(action.payload);
      return {
        ...state,
        listCount: action.payload.response.count,
        receiptList:[ ...state.receiptList, ...action.payload.response.results],
        loading: false,
        receiptParams: {
          ...state.receiptParams,
          no_of_pages: noofpages,
        },
      };
    },
    setReceiptParams: (state, action) => {
      return {
        ...state,
        receiptParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
        return {
          ...state,
          receiptParams: {
            ...state.receiptParams,
            [action.payload.key]: action.payload.value,
          },
        };
      },
      InputChangeValue: (state, action) => {
        return {
            ...state,
            receiptData: {
              ...state.receiptData,
              [action.payload.key]: action.payload.value,
            },
          };
      },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  isDrawerVisible,
  FilterInputChangeValue,
  InputChangeValue,
  setReceiptParams,
  getReceiptList,
  receiptListSuccessful,
  apiError,
} = receiptSlice.actions;

export default receiptSlice.reducer;
