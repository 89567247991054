import {
  API_ERROR,
  MENU,
  MENU_LIST_SUCCESSFUL,
  INPUT_CHANGEVALUE,
  MENU_ADD,
  MENU_ADD_SUCCESSFUL,
  MENU_ROW_DATA,
  MENU_ROW_DATA_SUCCESSFUL,
  MENU_DELETE,
  MENU_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
} from "./actionTypes";

export const getMenus = () => {
  return {
    type: MENU,
  };
};

export const MenuListSuccessful = (response) => {
  return {
    type: MENU_LIST_SUCCESSFUL,
    payload: response,
  };
};
export const InputChangeValue = ({ key, value }) => {
  return {
    type: INPUT_CHANGEVALUE,
    payload: { key, value },
  };
};

export const MenuAdd = (data) => {
  return {
    type: MENU_ADD,
    payload: { data },
  };
};

export const MenuAddSuccessful = (response) => {
  return {
    type: MENU_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const MenuRowData = (MenuId) => {
  return {
    type: MENU_ROW_DATA,
    payload: { MenuId },
  };
};

export const MenuDataSuccessful = (response) => {
  return {
    type: MENU_ROW_DATA_SUCCESSFUL,
    payload: response,
  };
};

export const MenuDelete = (MenuId) => {
  return {
    type: MENU_DELETE,
    payload: { MenuId },
  };
};

export const SetEditData = (data) => {
  return {
    type: MENU_SETROWDATA,
    payload: data,
  };
};

export const model_open = () => {
  return {
    type: MODEL_OPEN,
  };
};

export const model_close = () => {
  return {
    type: MODEL_CLOSE,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
