import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const miniSlice = createSlice({
  name: "mini",
  initialState: {
    userAccessList: [],
    miniCountryList: [],
    miniStatesList: [],
    miniCityList: [],
    miniAreaList: [],
    miniDistrictList: [],
    miniDepartmentList: [],
    miniFirmList: [],
    miniVehiclesList: [],
    miniVehiclesNonStockList: [],
    purchaseAvaliableStockList: [],
    purchaseStockList: [],
    saleQuotationStockList: [],
    miniCountersList: [],
    allCountersList: [],
    miniZonesList: [],
    miniClustersList: [],
    miniDivisionsList: [],
    miniBanksList: [],
    miniOccupationList: [],
    miniBrandsList: [],
    miniBrandFamilyList: [],
    miniColorsList: [],
    versionColorsList: [],
    miniFinanciersList: [],
    miniMechanicsList: [],
    miniAddressProofList: [],
    miniEmissionnorms: [],
    miniPriority: [],
    miniVersionList: [],
    miniModelsList: [],
    miniPercentageInterestsList: [],
    miniRejectReasonsList: [],
    miniPurchaseEnquiryList: [],
    miniSaleQuotationList: [],
    miniVehicleEstimationList: [],
    // miniEMIdatesList: [],
    miniInsuranceList: [],
    miniVehicletypesList: [],
    miniTenuresList: [],
    miniManagersList: [],
    searchVehicleNonStockId: 0,
    FVRequestUsersList: [],
    miniRepairTypesList: [],
    miniLeaveReasonList: [],
    FVRequestUsersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    purchaseEnquiryParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    miniVehiclesNonStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    miniVehiclesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    purchaseAvailableStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    purchaseStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    saleQuotationStockListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    getUserPermissions: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    userPermissionsSuccessful: (state) => {
      return {
        ...state,
        userAccessList: action.payload.response.data,
        loading: false,
      };
    },
    getMinicountry: (state, action) => {
      return {
        ...state,
        miniCountryList: [],
        miniStatesList: [],
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    minicountrySuccessful: (state, action) => {
      return {
        ...state,
        miniCountryList: action.payload,
        loading: false,
      };
    },
    getMinicountryClearData: (state, action) => {
      return {
        ...state,
        miniCountryList: [],
        miniStatesList: [],
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    getMiniStates: (state, action) => {
      return {
        ...state,
        miniStatesList: [],
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    miniStatesSuccessful: (state, action) => {
      return {
        ...state,
        miniStatesList: action.payload,
        loading: false,
      };
    },
    getMiniStatesClearData: (state, action) => {
      return {
        ...state,
        miniStatesList: [],
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
      };
    },
    getMiniDistrict: (state) => {
      return {
        ...state,
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    MiniDistrictSuccessful: (state, action) => {
      return {
        ...state,
        miniDistrictList: action.payload,
        loading: false,
      };
    },
    getMiniDistrictClearData: (state) => {
      return {
        ...state,
        miniDistrictList: [],
        miniCityList: [],
        miniAreaList: [],
      };
    },
    getMiniCity: (state) => {
      return {
        ...state,
        miniCityList: [],
        miniAreaList: [],
        loading: true,
      };
    },
    miniCitySuccessful: (state, action) => {
      return {
        ...state,
        miniCityList: action.payload,
        loading: false,
      };
    },
    getMiniCityClearData: (state) => {
      return {
        ...state,
        miniCityList: [],
        miniAreaList: [],
      };
    },
    getMiniArea: (state) => {
      return {
        ...state,
        miniAreaList: [],
        loading: true,
      };
    },
    miniAreaSuccessful: (state, action) => {
      return {
        ...state,
        miniAreaList: action.payload,
        loading: false,
      };
    },
    getMiniAreaClearData: (state) => {
      return {
        ...state,
        miniAreaList: [],
      };
    },
    getMiniDepartment: (state) => {
      return {
        ...state,
        miniDepartmentList: [],
        loading: true,
      };
    },
    miniDepartmentSuccessful: (state, action) => {
      return {
        ...state,
        miniDepartmentList: action.payload,
        loading: false,
      };
    },
    getMiniFirm: (state) => {
      return {
        ...state,
        miniFirmList: [],
        loading: true,
      };
    },
    miniFirmSuccessful: (state, action) => {
      return {
        ...state,
        miniFirmList: action.payload,
        loading: false,
      };
    },
    getMiniVehicles: (state) => {
      if (state.miniVehiclesParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniVehiclesList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniVehiclesSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniVehiclesParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniVehiclesList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniVehiclesParams?.page_size
      );
      return {
        ...state,
        miniVehiclesList: list,
        loading: false,
        miniVehiclesParams: {
          ...state.miniVehiclesParams,
          no_of_pages: noofpages,
        },
      };

      // var noofpages = Math.ceil(
      //   action.payload.count / state.miniVehiclesParams?.page_size
      // );
      // return {
      //   ...state,
      //   miniVehiclesList: [
      //     ...state.miniVehiclesList,
      //     ...action.payload.results,
      //   ],
      //   loading: false,
      //   miniVehiclesParams: {
      //     ...state.miniVehiclesParams,
      //     no_of_pages: noofpages,
      //   },
      // };
    },
    setMiniVehiclesParams: (state, action) => {
      return {
        ...state,
        miniVehiclesParams: action.payload,
      };
    },
    getMiniVehiclesNonStock: (state, action) => {
      if (state.miniVehiclesNonStockParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniVehiclesNonStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniVehiclesNonStockSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniVehiclesNonStockParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniVehiclesNonStockList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniVehiclesNonStockParams?.page_size
      );
      return {
        ...state,
        miniVehiclesNonStockList: list,
        loading: false,
        miniVehiclesNonStockParams: {
          ...state.miniVehiclesNonStockParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniVehiclesNonStockParams: (state, action) => {
      return {
        ...state,
        miniVehiclesNonStockParams: action.payload,
      };
    },
    getPurchaseAvailableStock: (state, action) => {
      if (state.purchaseAvailableStockParams?.page == 1) {
        return {
          ...state,
          loading: true,
          purchaseAvaliableStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    purchaseAvailableStockSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.purchaseAvailableStockParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.purchaseAvaliableStockList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.purchaseAvailableStockParams?.page_size
      );
      return {
        ...state,
        purchaseAvaliableStockList: list,
        loading: false,
        purchaseAvailableStockParams: {
          ...state.purchaseAvailableStockParams,
          no_of_pages: noofpages,
        },
      };

      
      // var noofpages = Math.ceil(
      //   action.payload.count / state.purchaseAvailableStockParams?.page_size
      // );
      // return {
      //   ...state,
      //   purchaseAvaliableStockList: [
      //     ...state.purchaseAvaliableStockList,
      //     ...action.payload.results,
      //   ],
      //   loading: false,
      //   purchaseAvailableStockParams: {
      //     ...state.purchaseAvailableStockParams,
      //     no_of_pages: noofpages,
      //   },
      // };
    },
    clearPurchaseAvaliableStock: (state)=>{
      return{
        ...state,
        purchaseAvaliableStockList: [],
      }
    },
    setPurchaseAvailableStockParams: (state, action) => {
      return {
        ...state,
        purchaseAvailableStockParams: action.payload,
      };
    },

    getPurchaseStock: (state, action) => {
      if (state.purchaseStockParams?.page == 1) {
        return {
          ...state,
          loading: true,
          purchaseStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    purchaseStockSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.purchaseStockParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.purchaseStockList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.purchaseStockParams?.page_size
      );
      return {
        ...state,
        purchaseStockList: list,
        loading: false,
        purchaseStockParams: {
          ...state.purchaseStockParams,
          no_of_pages: noofpages,
        },
      };
    },
    setPurchaseStockParams: (state, action) => {
      return {
        ...state,
        purchaseStockParams: action.payload,
      };
    },
    getMiniCounters: (state) => {
      return {
        ...state,
        miniCountersList: [],
        loading: true,
      };
    },
    miniCountersSuccessful: (state, action) => {
      return {
        ...state,
        miniCountersList: action.payload,
        loading: false,
      };
    },
    getMiniCountersClearData: (state) => {
      return {
        ...state,
        miniCountersList: [],
      };
    },
    getAllCounters: (state) => {
      return {
        ...state,
        allCountersList: [],
        loading: true,
      };
    },
    allCountersSuccessful: (state, action) => {
      return {
        ...state,
        allCountersList: action.payload,
        loading: false,
      };
    },
    getMiniZones: (state) => {
      return {
        ...state,
        miniZonesList: [],
        loading: true,
      };
    },
    miniZonesSuccessful: (state, action) => {
      return {
        ...state,
        miniZonesList: action.payload,
        loading: false,
      };
    },
    getMiniZonesClearData: (state) => {
      return {
        ...state,
        miniZonesList: [],
      };
    },
    getMiniClusters: (state) => {
      return {
        ...state,
        miniClustersList: [],
        loading: true,
      };
    },
    miniClustersSuccessful: (state, action) => {
      return {
        ...state,
        miniClustersList: action.payload,
        loading: false,
      };
    },
    getMiniClustersClearData: (state) => {
      return {
        ...state,
        miniClustersList: [],
      };
    },
    getMiniDivisions: (state) => {
      return {
        ...state,
        miniDivisionsList: [],
        loading: true,
      };
    },
    miniDivisionsSuccessful: (state, action) => {
      return {
        ...state,
        miniDivisionsList: action.payload,
        loading: false,
      };
    },
    getMiniDivisionsClearData: (state) => {
      return {
        ...state,
        miniDivisionsList: [],
      };
    },
    getMiniBanks: (state) => {
      return {
        ...state,
        miniBanksList: [],
        loading: true,
      };
    },
    miniBanksSuccessful: (state, action) => {
      return {
        ...state,
        miniBanksList: action.payload,
        loading: false,
      };
    },
    getMiniOccupation: (state) => {
      return {
        ...state,
        miniOccupationList: [],
        loading: true,
      };
    },
    miniOccupationSuccessful: (state, action) => {
      return {
        ...state,
        miniOccupationList: action.payload,
        loading: false,
      };
    },
    getMiniBrands: (state) => {
      return {
        ...state,
        miniBrandsList: [],
        loading: true,
      };
    },
    miniBrandsSuccessful: (state, action) => {
      return {
        ...state,
        miniBrandsList: action.payload,
        loading: false,
      };
    },
    getMiniBrandsClearData: (state) => {
      return {
        ...state,
        miniBrandsList: [],
        loading: true,
      };
    },
    getMiniBrandFamily: (state) => {
      return {
        ...state,
        miniBrandFamilyList: [],
        loading: true,
      };
    },
    miniBrandFamilySuccessful: (state, action) => {
      return {
        ...state,
        miniBrandFamilyList: action.payload,
        loading: false,
      };
    },
    getMiniBrandFamilyClearData: (state) => {
      return {
        ...state,
        miniBrandFamilyList: [],
        loading: true,
      };
    },
    getMiniColors: (state) => {
      return {
        ...state,
        miniColorsList: [],
        loading: true,
      };
    },
    miniColorsSuccessful: (state, action) => {
      return {
        ...state,
        miniColorsList: action.payload,
        loading: false,
      };
    },
    getVersionColors: (state) => {
      return {
        ...state,
        versionColorsList: [],
        loading: true,
      };
    },
    versionColorsSuccessful: (state, action) => {
      return {
        ...state,
        versionColorsList: action.payload,
        loading: false,
      };
    },
    getMiniFinanciers: (state) => {
      return {
        ...state,
        miniFinanciersList: [],
        loading: true,
      };
    },
    miniFinanciersSuccessful: (state, action) => {
      return {
        ...state,
        miniFinanciersList: action.payload,
        loading: false,
      };
    },
    getMiniMechanics: (state) => {
      return {
        ...state,
        miniMechanicsList: [],
        loading: true,
      };
    },
    miniMechanicsSuccessful: (state, action) => {
      return {
        ...state,
        miniMechanicsList: action.payload,
        loading: false,
      };
    },
    getMiniAddressProof: (state) => {
      return {
        ...state,
        miniAddressProofList: [],
        loading: true,
      };
    },
    miniAddressProofSuccessful: (state, action) => {
      return {
        ...state,
        miniAddressProofList: action.payload,
        loading: false,
      };
    },
    getMiniEmissionnorms: (state) => {
      return {
        ...state,
        miniEmissionnorms: [],
        loading: true,
      };
    },
    miniEmissionnormsSuccessful: (state, action) => {
      return {
        ...state,
        miniEmissionnorms: action.payload,
        loading: false,
      };
    },
    getMiniPriority: (state) => {
      return {
        ...state,
        miniPriority: [],
        loading: true,
      };
    },
    miniPrioritySuccessful: (state, action) => {
      return {
        ...state,
        miniPriority: action.payload,
        loading: false,
      };
    },
    getMiniPriorityClearData: (state) => {
      return {
        ...state,
        miniPriority: [],
        loading: true,
      };
    },
    getMiniVersion: (state) => {
      return {
        ...state,
        miniVersionList: [],
        loading: true,
      };
    },
    miniVersionSuccessful: (state, action) => {
      return {
        ...state,
        miniVersionList: action.payload,
        loading: false,
      };
    },
    getMiniVersionClearData: (state) => {
      return {
        ...state,
        miniVersionList: [],
        loading: true,
      };
    },
    getMiniModels: (state) => {
      return {
        ...state,
        miniModelsList: [],
        loading: true,
      };
    },
    miniModelsSuccessful: (state, action) => {
      return {
        ...state,
        miniModelsList: action.payload,
        loading: false,
      };
    },
    getMiniModelsClearData: (state) => {
      return {
        ...state,
        miniModelsList: [],
        loading: true,
      };
    },
    getMiniPercentageInterests: (state) => {
      return {
        ...state,
        miniPercentageInterestsList: [],
        loading: true,
      };
    },
    miniPercentageInterestsSuccessful: (state, action) => {
      return {
        ...state,
        miniPercentageInterestsList: action.payload,
        loading: false,
      };
    },
    getMiniRejectReasons: (state) => {
      return {
        ...state,
        miniRejectReasonsList: [],
        loading: true,
      };
    },
    miniRejectReasonsSuccessful: (state, action) => {
      return {
        ...state,
        miniRejectReasonsList: action.payload,
        loading: false,
      };
    },
    getMiniPurchaseEnquiry: (state, action) => {
      if (state.purchaseEnquiryParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniPurchaseEnquiryList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniPurchaseEnquirySuccessful: (state, action) => {
      var response = action.payload.response;
      var list = [];
      if (state.purchaseEnquiryParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniPurchaseEnquiryList, ...response.results];
      }
      var noofpages = Math.ceil(
        response.count / state.purchaseEnquiryParams?.page_size
      );
      return {
        ...state,
        miniPurchaseEnquiryList: list,
        loading: false,
        purchaseEnquiryParams: {
          ...state.purchaseEnquiryParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniPurchaseEnquiryParams: (state, action) => {
      return {
        ...state,
        purchaseEnquiryParams: action.payload,
      };
    },
    getMiniVehicleEstimation: (state) => {
      return {
        ...state,
        miniVehicleEstimationList: [],
        loading: true,
      };
    },
    miniVehicleEstimationSuccessful: (state, action) => {
      return {
        ...state,
        miniVehicleEstimationList: action.payload,
        loading: false,
      };
    },
    // getMiniEMIdates: (state) => {
    //   return {
    //     ...state,
    //     miniEMIdatesList: [],
    //     loading: true,
    //   };
    // },
    // miniEMIdatesSuccessful: (state, action) => {
      
    //   return {
    //     ...state,
    //     miniEMIdatesList: action.payload,
    //     loading: false,
    //   };
    // },
    getMiniInsurance: (state) => {
      return {
        ...state,
        miniInsuranceList: [],
        loading: true,
      };
    },
    miniInsuranceSuccessful: (state, action) => {
      return {
        ...state,
        miniInsuranceList: action.payload,
        loading: false,
      };
    },
    getMiniShowroom: (state) => {
      return {
        ...state,
        miniShowroomList: [],
        loading: true,
      };
    },
    miniShowroomSuccessful: (state, action) => {
      return {
        ...state,
        miniShowroomList: action.payload,
        loading: false,
      };
    },
    getMiniVehicletypes: (state) => {
      return {
        ...state,
        miniVehicletypesList: [],
        loading: true,
      };
    },
    miniVehicletypesSuccessful: (state, action) => {
      return {
        ...state,
        miniVehicletypesList: action.payload,
        loading: false,
      };
    },
    getMiniVehicletypesClearData: (state) => {
      return {
        ...state,
        miniVehicletypesList: [],
        loading: true,
      };
    },
    getMiniTenures: (state) => {
      return {
        ...state,
        miniTenuresList: [],
        loading: true,
      };
    },
    miniTenuresSuccessful: (state, action) => {
      action.payload = action.payload.sort((a, b) =>
      a.sequence > b.sequence ? 1 : -1);
      return {
        ...state,
        miniTenuresList: action.payload,
        loading: false,
      };
    },
    getSaleQuotationStockList: (state, action) => {
      if (state.saleQuotationStockListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          saleQuotationStockList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    saleQuotationStockListSuccessful: (state, action) => {
      var response = action.payload.response;
      var list = [];
      if (state.saleQuotationStockListParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.saleQuotationStockList, ...response.results];
      }
      var noofpages = Math.ceil(
        response.count / state.saleQuotationStockListParams?.page_size
      );
      return {
        ...state,
        saleQuotationStockList: list,
        loading: false,
        saleQuotationStockListParams: {
          ...state.saleQuotationStockListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setSaleQuotationStockListParams: (state, action) => {
      return {
        ...state,
        saleQuotationStockListParams: action.payload,
      };
    },
    getMiniManagers: (state) => {
      return {
        ...state,
        miniManagersList: [],
        loading: true,
      };
    },
    miniManagersSuccessful: (state, action) => {
      return {
        ...state,
        miniManagersList: action.payload,
        loading: false,
      };
    },
    getFVRequestUsers: (state) => {
      if (state.FVRequestUsersParams?.page == 1) {
        return {
          ...state,
          loading: true,
          FVRequestUsersList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    FVRequestUsersSuccessful: (state, action) => {
      console.log("FVRequestUsersSuccessful", action.payload);
      var list = [];
      if (state.FVRequestUsersParams?.page == 1) {
        list = action.payload.response.results;
      } else {
        list = [
          ...state.FVRequestUsersList,
          ...action.payload.response.results,
        ];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.FVRequestUsersParams?.page_size
      );
      return {
        ...state,
        FVRequestUsersList: list,
        loading: false,
        FVRequestUsersParams: {
          ...state.FVRequestUsersParams,
          no_of_pages: noofpages,
        },
      };
    },
    setFVRequestUsersParams: (state, action) => {
      return {
        ...state,
        FVRequestUsersParams: action.payload,
      };
    },
    getMiniRepairTypes: (state) => {
      return {
        ...state,
        miniRepairTypesList: [],
        loading: true,
      };
    },
    miniRepairTypSuccessful: (state, action) => {
      return {
        ...state,
        miniRepairTypesList: action.payload,
        loading: false,
      };
    },
    getMiniLeaveReason: (state) => {
      return {
        ...state,
        miniLeaveReasonList: [],
        loading: true,
      };
    },
    miniLeaveReasonSuccessful: (state, action) => {
      return {
        ...state,
        miniLeaveReasonList: action.payload,
        loading: false,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUserPermissions,
  userPermissionsSuccessful,
  getMinicountry,
  minicountrySuccessful,
  getMiniStates,
  miniStatesSuccessful,
  getMiniStatesClearData,
  getMiniCity,
  miniCitySuccessful,
  getMiniCityClearData,
  getMiniDistrict,
  MiniDistrictSuccessful,
  getMiniDistrictClearData,
  getMiniArea,
  miniAreaSuccessful,
  getMiniAreaClearData,
  getMiniDepartment,
  miniDepartmentSuccessful,
  getMiniFirm,
  miniFirmSuccessful,
  getMiniVehicles,
  miniVehiclesSuccessful,
  setMiniVehiclesParams,
  getMiniVehiclesNonStock,
  miniVehiclesNonStockSuccessful,
  setMiniVehiclesNonStockParams,
  getPurchaseAvailableStock,
  purchaseAvailableStockSuccessful,
  setPurchaseAvailableStockParams,
  getMiniCounters,
  miniCountersSuccessful,
  getMiniCountersClearData,
  getAllCounters,
  allCountersSuccessful,
  getMiniZones,
  miniZonesSuccessful,
  getMiniZonesClearData,
  getMiniClusters,
  miniClustersSuccessful,
  getMiniClustersClearData,
  getMiniDivisions,
  miniDivisionsSuccessful,
  getMiniDivisionsClearData,
  getMiniBanks,
  miniBanksSuccessful,
  getMiniOccupation,
  miniOccupationSuccessful,
  getMiniBrands,
  miniBrandsSuccessful,
  getMiniBrandsClearData,
  getMiniBrandFamily,
  miniBrandFamilySuccessful,
  getMiniBrandFamilyClearData,
  getMiniColors,
  miniColorsSuccessful,
  getVersionColors,
  versionColorsSuccessful,
  getMiniFinanciers,
  miniFinanciersSuccessful,
  getMiniMechanics,
  miniMechanicsSuccessful,
  getMiniAddressProof,
  miniAddressProofSuccessful,
  getMiniEmissionnorms,
  miniEmissionnormsSuccessful,
  getMiniPriority,
  miniPrioritySuccessful,
  getMiniPriorityClearData,
  getMiniVersion,
  miniVersionSuccessful,
  getMiniVersionClearData,
  getMiniModels,
  miniModelsSuccessful,
  getMiniModelsClearData,
  getMiniPercentageInterests,
  miniPercentageInterestsSuccessful,
  getMiniRejectReasons,
  miniRejectReasonsSuccessful,
  getMiniPurchaseEnquiry,
  miniPurchaseEnquirySuccessful,
  setMiniPurchaseEnquiryParams,
  getMiniSaleQuotation,
  miniSaleQuotationSuccessful,
  getMiniVehicleEstimation,
  miniVehicleEstimationSuccessful,
  // getMiniEMIdates,
  // miniEMIdatesSuccessful,
  getMiniInsurance,
  miniInsuranceSuccessful,
  getMiniShowroom,
  miniShowroomSuccessful,
  getMiniVehicletypes,
  miniVehicletypesSuccessful,
  getMiniVehicletypesClearData,
  getMiniTenures,
  miniTenuresSuccessful,
  getSaleQuotationStockList,
  saleQuotationStockListSuccessful,
  setSaleQuotationStockListParams,
  getMiniManagers,
  miniManagersSuccessful,
  getMinicountryClearData,
  getPurchaseStock,
  purchaseStockSuccessful,
  setPurchaseStockParams,
  getFVRequestUsers,
  FVRequestUsersSuccessful,
  setFVRequestUsersParams,
  getMiniRepairTypes,
  miniRepairTypSuccessful,
  clearPurchaseAvaliableStock,
  getMiniLeaveReason,
  miniLeaveReasonSuccessful,
  apiError,
} = miniSlice.actions;

export default miniSlice.reducer;
