import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getPurchaseList,
  purchaseListSuccessful,
  purchaseDocSuccessful,
  counterByIDSuccessful,
  vehicleDataSuccessful,
  purchasesImageUploadSuccessful,
  FormulaExecuterSuccessful,
  purchasesCheckApproveSuccessful,
  purchaseHistoryListSuccessful,
  saleHistoryListSuccessful,
  getPurchaseDoc,
  isRejectModelVisible,
  purchaseEnquirySuccessful,
  getPurchaseCheckApprove,
  addPurchaseSuccessful,
  repairAmountListSuccessful,
  reEstimationListSuccessful,
  InputChangeValue,
} from "./vehicleSlice";
import { getVersionColors } from "../../mini/miniSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { select } from "redux-saga/effects";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import vehicleData from "./vehicleData.json";

//If user is login then dispatch redux action's are directly from here.
function* PurchaseList({ payload }) {
  var data = payload;
  var params = {};
  // for (const k in data) {
  //   if (Object.hasOwnProperty.call(data, k)) {
  //     if (k === "currentSort" || k === "sortOrder") {
  //       params.ordering = data.sortOrder + data.currentSort;
  //     } else {
  //       if (data[k] !== "" && k !== "no_of_pages") {
  //         params[k] = data[k];
  //       }
  //     }
  //   }
  // }
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const VehicleData = vehicleData;
    // const response = yield call(getParamsList, "/purchase/list/", params);
    yield put(purchaseListSuccessful({ VehicleData }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* PurchaseAdd({ payload }) {
  // var data = {};
  // for (const k in payload.purchaseObj) {
  //   if (Object.hasOwnProperty.call(payload.purchaseObj, k)) {
  //     if (payload.purchaseObj[k] !== null && payload.purchaseObj[k] !== "") {
  //       data[k] = payload.purchaseObj[k];
  //     }
  //   }
  // }
  try {
    if (payload.purchaseObj.id == 0) {
      const response = yield call(
        postAdd,
        "/purchase/create/",
        payload.purchaseObj
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Purchase Added Successfully.!
              </p>
            </div>
          ),
        });

        payload.history.push("/pages/purchases");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Purchase. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/purchase/" + payload.purchaseObj.id,
        payload.purchaseObj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Purchase Edited Successfully.!
              </p>
            </div>
          ),
        });

        payload.history.push("/pages/purchases");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Purchase. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
    yield put(addPurchaseSuccessful(null));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(addPurchaseSuccessful(null));
    yield put(apiError(error));
  }
}

// Purchase History List
function* PurchaseHistory({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/purchase/byvehicleid/" + payload.id,
      {}
    );
    yield put(purchaseHistoryListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Sale History List

function* SaleHistory({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/sale/byvehicleid/" + payload.id,
      {}
    );
    yield put(saleHistoryListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* purchaseEnquiry({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/enquiry/list/", data);
    yield put(purchaseEnquirySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* PurchaseApproval({ payload }) {
  try {
    const response = yield call(postAdd, "/purchase/approve/", payload);
    if (response.status === 201) {
      if (payload.approvalstatus == 2) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Purchase Approve Successfully.!
              </p>
            </div>
          ),
        });
      } else if (payload.approvalstatus == 3) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Purchase Reject Successfully.!
              </p>
            </div>
          ),
        });
      }

      yield put(getPurchaseDoc(payload.purchase_id));
      yield put(getPurchaseCheckApprove({ purchase_id: payload.purchase_id }));
      yield put(isRejectModelVisible(false));
    } else {
      if (payload.approvalstatus == 2) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Approve Purchase. Please try again!
              </p>
            </div>
          ),
        });
      } else if (payload.approvalstatus == 3) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Reject Purchase. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* PurchaseCheckApprove({ payload }) {
  try {
    const response = yield call(postAdd, "/purchase/checkapprove/", payload);
    if (response.status === 200) {
      yield put(purchasesCheckApproveSuccessful(true));
    }
  } catch (error) {
    yield put(purchasesCheckApproveSuccessful(false));
    yield put(apiError(error));
  }
}

function* PurchaseDoc({ payload }) {
  try {
    const response = yield call(getList, "/purchase/" + payload, {});
    yield put(purchaseDocSuccessful(response));
    yield put(getVersionColors(response.vehicle?.version?.model?.id));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* CounterByIDData({ payload }) {
  try {
    const response = yield call(getList, "/masters/counters/" + payload, {});
    yield put(counterByIDSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* VehicleDataGet({ payload }) {
  try {
    const response = yield call(getList, "/masters/vehicles/" + payload, {});
    yield put(vehicleDataSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* FormulaExecuter({ payload }) {
  let systemFormulasList = yield select(
    (state) => state.system.systemFormulasList
  );

  try {
    var formulaObj = systemFormulasList?.find((formula) => {
      return formula?.code === payload.code;
    });

    if (formulaObj) {
      var result = evaluate(formulaObj.formula, payload.variables);

      if (result !== "NaN") {
        yield put(
          FormulaExecuterSuccessful({
            response: result,
            formulaCode: payload.code,
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to calculator. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
    // try {
    //   const response = yield call(postAdd, "/system/formulaexecuter/", payload);
    //   if (response.status === 200) {
    //     yield put(
    //       FormulaExecuterSuccessful({
    //         response: response.data,
    //         formulaCode: payload.code,
    //       })
    //     );
    //   } else {
    //     Modal.error({
    //       icon: (
    //         <span className="remix-icon">
    //           <RiAlertLine />
    //         </span>
    //       ),
    //       title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
    //       content: (
    //         <div>
    //           <p className="hp-p1-body hp-text-color-black-80">
    //             Sorry! Unable to calculator. Please try again!
    //           </p>
    //         </div>
    //       ),
    //     });
    //   }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* PurchasesImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      purchasesImageUploadSuccessful({ response, ImageType: payload.imageType })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}
function* PurchaseDelete({ payload }) {
  try {
    const response = yield call(postDelete, "/purchase/" + payload.id);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Purchase has been deleted!
            </p>
          </div>
        ),
      });

      yield put(getPurchaseList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Purchase has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// Repair Amount

function* RepairAmount({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/repairamount/bypurchaseid/" + payload.id,
      {}
    );
    yield put(repairAmountListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Re Estimation

function* ReEstimation({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfer/reestimationamount/bypurchaseid/" + payload.id,
      {}
    );
    yield put(reEstimationListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* InsuranceValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/purchase/insurancenumber/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "insurancenumber",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response?.data?.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}

export function* watchGetPurchaseList() {
  yield takeEvery("vehicle/getPurchaseList", PurchaseList);
}
export function* watchAddPurchase() {
  yield takeEvery("vehicle/addPurchase", PurchaseAdd);
}
export function* watchGetPurchaseDoc() {
  yield takeEvery("vehicle/getPurchaseDoc", PurchaseDoc);
}
export function* watchGetCounterByID() {
  yield takeEvery("vehicle/GetCounterByID", CounterByIDData);
}
export function* watchGetVehicleData() {
  yield takeEvery("vehicle/getVehicleData", VehicleDataGet);
}
export function* watchGetFormulaExecuter() {
  yield takeEvery("vehicle/getFormulaExecuter", FormulaExecuter);
}
export function* watchGetPurchasesImageUpload() {
  yield takeEvery("vehicle/getPurchasesImageUpload", PurchasesImageUpload);
}
export function* watchPurchaseApproval() {
  yield takeEvery("vehicle/purchaseApproval", PurchaseApproval);
}
export function* watchGetPurchaseCheckApprove() {
  yield takeEvery("vehicle/getPurchaseCheckApprove", PurchaseCheckApprove);
}

export function* watchGetPurchaseHistory() {
  yield takeEvery("vehicle/getPurchaseHistory", PurchaseHistory);
}

export function* watchGetSaleHistory() {
  yield takeEvery("vehicle/getSaleHistory", SaleHistory);
}
export function* watchGetPurchaseEnquiry() {
  yield takeEvery("vehicle/getPurchaseEnquiry", purchaseEnquiry);
}
export function* watchGetPurchaseDelete() {
  yield takeEvery("vehicle/purchaseDelete", PurchaseDelete);
}
export function* watchGetRepairAmount() {
  yield takeEvery("vehicle/getRepairAmount", RepairAmount);
}
export function* watchGetReEstimation() {
  yield takeEvery("vehicle/getReEstimation", ReEstimation);
}
export function* watchInsuranceNumberValidate() {
  yield takeEvery("vehicle/insuranceNumberValidate", InsuranceValidate);
}
function* vehicleSaga() {
  yield all([
    fork(watchGetPurchaseList),
    fork(watchAddPurchase),
    fork(watchGetPurchaseDoc),
    fork(watchGetCounterByID),
    fork(watchGetVehicleData),
    fork(watchGetPurchasesImageUpload),
    fork(watchGetFormulaExecuter),
    fork(watchPurchaseApproval),
    fork(watchGetPurchaseCheckApprove),
    fork(watchGetPurchaseHistory),
    fork(watchGetSaleHistory),
    fork(watchGetPurchaseEnquiry),
    fork(watchGetPurchaseDelete),
    fork(watchGetRepairAmount),
    fork(watchGetReEstimation),
    fork(watchInsuranceNumberValidate),
  ]);
}

export default vehicleSaga;
