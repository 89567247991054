import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getFormulas,
  formulaListSuccessful,
  formulaAddSuccessful,
  isModelVisible,
} from "./formulaSlice";

// AUTH related methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import Swal from "sweetalert2";

function* getFormula({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/system/formula/", data);
    console.log("resrponce",response )
    yield put(formulaListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddFormula({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/system/formula/", payload);
      if (response.status === 201) {
        Swal.fire({
          title: "Your Formula Added Successfully.!",
          icon: "success",
          timer: 1500,
        });
        yield put(getFormulas({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Swal.fire({
          title: "Sorry! Unable to added Formula. Please try again!",
          icon: "error",
        });
      }
      yield put(formulaAddSuccessful(null));
    } else {
      const response = yield call(
        postEdit,
        "/system/formula/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Swal.fire({
          title: "Your Formula Edited Successfully.!",
          icon: "success",
          timer: 1500,
        });
        yield put(getFormulas({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Swal.fire({
          title: "Sorry! Unable to edit Formula. Please try again!",
          icon: "error",
        });
      }
      yield put(formulaAddSuccessful(null));
    }
  } catch (error) {
    Swal.fire({
      title: "Sorry! Permission Denied!",
      icon: "error",
    });
    yield put(apiError(error));
  }
}

function* DeleteFromula({ payload }) {
  var formulaID = payload;
  try {
    const response = yield call(postDelete, "/system/formula/" + formulaID);

    if (response.status === 204) {
      Swal.fire({
        title: " Your Formula has been deleted!",
        icon: "success",
        timer: 1500,
      });
      yield put(getFormulas({ page: 1, search: "", page_size: 10 }));
    } else {
      Swal.fire({
        title: "Sorry! Your Formula has been not deleted!",
        icon: "error",
      });
    }
  } catch (error) {
    Swal.fire({
      title: "Sorry! Permission Denied!",
      icon: "error",
    });

    yield put(apiError(error));
  }
}

export function* watchgetFormulas() {
  yield takeEvery("formula/getFormulas", getFormula);
}
export function* watchFormulaAdd() {
  yield takeEvery("formula/formulaAdd", AddFormula);
}

export function* watchFormulaDelete() {
  yield takeEvery("formula/formulaDelete", DeleteFromula);
}

function* FormulaSaga() {
  yield all([
    fork(watchgetFormulas),
    fork(watchFormulaAdd),
    fork(watchFormulaDelete),
  ]);
}

export default FormulaSaga;
