import { createSlice } from "@reduxjs/toolkit";

export const banksSlice = createSlice({
  name: "banks",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    bankslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    bankParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getBanks: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    banksListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.bankParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        bankslist: action.payload.response.results,
        loading: false,
        bankParams: {
          ...state.bankParams,
          no_of_pages: noofpages,
        },
      };
    },
    banksAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    banksAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    banksEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    banksEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    banksDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetBankParams: (state, action) => {
      return {
        ...state,
        bankParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getBanks,
  banksListSuccessful,
  banksAdd,
  banksAddSuccessful,
  banksEdit,
  banksEditSuccessful,
  banksDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetBankParams
} = banksSlice.actions;

export default banksSlice.reducer;
