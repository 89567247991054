import { createSlice } from "@reduxjs/toolkit";

export const dealersSlice = createSlice({
  name: "dealers",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    dealerslist: [],
    model: false,
    error_msg: null,
    vendorData: {},
    listCount: 0,
    searchDealerId: 0,
    dealersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    // id wise search code....
    // getDealers: (state, action) => {
    //   if (state.dealersParams?.page == 1) {
    //     return {
    //       ...state,
    //       loading: true,
    //       dealerslist: [],
    //       searchDealerId: action.payload.searchDealerId == undefined ? 0 : action.payload.searchDealerId
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       loading: true,
    //       searchDealerId: action.payload.searchDealerId == undefined ? 0 : action.payload.searchDealerId
    //     };
    //   }
    // },
    // dealersListSuccessful: (state, action) => {
    //   var response = action.payload.response
    //   var searchDealerId = action.payload.searchDealerId
    //   if(searchDealerId == state.searchDealerId){
    //   var noofpages = Math.ceil(
    //    response.count / state.dealersParams.page_size
    //   );
    //   return {
    //     ...state,
    //     listCount: response.count,
    //     dealerslist: [...state.dealerslist, ...response.results],
    //     loading: false,
    //     dealersParams: {
    //       ...state.dealersParams,
    //       no_of_pages: noofpages,
    //     },
    //   };
    // }},
    getDealers: (state, action) => {
      if (state.dealersParams?.page == 1) {
        return {
          ...state,
          loading: true,
          dealerslist: [],
          searchDealerId:
            action.payload.searchDealerId == undefined
              ? 0
              : action.payload.searchDealerId,
        };
      } else {
        return {
          ...state,
          loading: true,
          searchDealerId:
            action.payload.searchDealerId == undefined
              ? 0
              : action.payload.searchDealerId,
        };
      }
    },
    dealersListSuccessful: (state, action) => {
      var dealerslist = [];
      var response = action.payload.VendorsData;
      var searchDealerId = action.payload.searchDealerId;
      if (state.dealersParams?.page == 1) {
        dealerslist = response.results;
      } else {
        dealerslist = [...state.dealerslist, ...response.results];
      }
      // if(searchDealerId == state.searchDealerId){
      var noofpages = Math.ceil(response.count / state.dealersParams.page_size);
      return {
        ...state,
        listCount: response.count,
        dealerslist: dealerslist,
        // dealerslist: [...state.dealerslist, ...response.results],
        loading: false,
        dealersParams: {
          ...state.dealersParams,
          no_of_pages: noofpages,
        },
      };
      // }
    },
    clearDealerList: (state, action) => {
     return {
          ...state,
          loading: true,
          dealerslist: [],
        };
    },
    dealerAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    dealerAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        vendorData: {},
      };
    },
    dealerEdit: (state, action) => {
      return {
        ...state,
        model: true,
        vendorData: {
          id: action.payload?.id,
          username: action.payload?.user.username,
          email: action.payload?.user.email,
          phone: action.payload?.user.phone,
          // group_ids: groupIds,
          first_name: action.payload?.user.first_name,
          last_name: action.payload?.user.last_name,
          state_id: action.payload?.user.state?.id,
          district_id: action.payload?.user.district?.id,
          city_id: action.payload?.user.city?.id,
          area_id: action.payload?.user.area?.id,
          address: action.payload?.user.address,
          pincode: action.payload?.user.pincode,
          password: action.payload?.user.password,
          bank_id: action.payload?.bank.id,
          ifsccode: action.payload?.ifsccode,
          gstno: action.payload?.gstno,
          aadharno: action.payload?.aadharno,
          accono: action.payload?.accono,
          // dob: action.payload?.dob,
        },
      };
    },
    dealerEditSuccessful: (state) => {
      return {
        ...state,
        loading: false,
        vendorData: {},
      };
    },
    dealerDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      if (action.payload.key == "state_id") {
        return {
          ...state,
          vendorData: {
            ...state.vendorData,
            [action.payload.key]: action.payload.value,
            district_id: null,
            city_id: null,
            area_id: null,
          },
        };
      } else if (action.payload.key == "district_id") {
        return {
          ...state,
          vendorData: {
            ...state.vendorData,
            [action.payload.key]: action.payload.value,
            city_id: null,
            area_id: null,
          },
        };
      } else if (action.payload.key == "city_id") {
        return {
          ...state,
          vendorData: {
            ...state.vendorData,
            [action.payload.key]: action.payload.value,
            area_id: null,
          },
        };
      } else {
        return {
          ...state,
          vendorData: {
            ...state.vendorData,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          vendorData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetDealersParams: (state, action) => {
      return {
        ...state,
        dealersParams: action.payload,
      };
    },
    userMobileValidation: (state) => {
      return {
        ...state,
      };
    },
    userEmailValidation: (state) => {
      return {
        ...state,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getDealers,
  dealersListSuccessful,
  dealerAdd,
  dealerAddSuccessful,
  dealerEdit,
  dealerEditSuccessful,
  dealerDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetDealersParams,
  userMobileValidation,
  userEmailValidation,
  clearDealerList
} = dealersSlice.actions;

export default dealersSlice.reducer;
