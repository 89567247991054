// import { createSlice } from "@reduxjs/toolkit";
import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";
export const manageProfileSlice = createSlice({
  name: "manageProfile",
  initialState: {
    listCount: 0,
    profileList: [],
    appPermissionsList: [],
    checkedPermissions: [],
    profileData: {},
    model: false,
    loading: false,
    permissionsLoading: false,
    profileParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getProfileList: (state) => {
      if (state.profileParams.page == 1) {
        return {
          ...state,
          loading: true,
          profileList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    profileListSuccessful: (state, action) => {
      console.log("profileListSuccessful", action.payload);
      // var list = [];
      // if (state.profileParams?.page == 1) {
      //   list = action.payload.results;
      // } else {
      //   list = [...state.profileList, ...action.payload.results];
      // }
      var noofpages = Math.ceil(
        action.payload.count / state.profileParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        profileList: action.payload.results,
        loading: false,
        profileParams: {
          ...state.profileParams,
          no_of_pages: noofpages,
        },
      };

      // var response = action.payload;
      // var list = [];
      // if (state.profileParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.profileList, ...response.results];
      // }
      // var noofpages = Math.ceil(
      //   action.payload.count / state.profileParams?.page_size
      // );
      // return {
      //   ...state,
      //   profileList: list,
      //   listCount: response.count,
      //   loading: false,
      //   profileParams: {
      //     ...state.profileParams,
      //     no_of_pages: noofpages,
      //   },
      // };
    },
    getPermissionsList: (state) => {
      return {
        ...state,
        permissionsLoading: true,
        appPermissionsList: [],
      };
    },
    permissionsListSuccessful: (state, action) => {
      var permissionlist = [];
      permissionlist = action.payload.map((item) => {
        var contentTypeList = item.contenttypedetails.map((appitem) => {
          var permissionList = appitem.permissions.map((permission) => {
            permission.permissionChecked = false;
            return permission;
          });
          appitem.permissions = permissionList;
          appitem.contentTypeChecked = false;
          return appitem;
        });

        item.appPermissionsChecked = false;
        item.contenttypedetails = contentTypeList;
        return item;
      });
      return {
        ...state,
        appPermissionsList: permissionlist,
        permissionsLoading: false,
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    checkAppPermission: (state, action) => {
      state.appPermissionsList.map((item) => {
        if (item.id === action.payload.appPermission_id) {
          item.appPermissionsChecked = !item.appPermissionsChecked;
          if (item.appPermissionsChecked == true) {
            item.contenttypedetails.map((appitem) => {
              item.appPermissionsChecked = true;
              appitem.permissions.map((permission) => {
                permission.permissionChecked = true;
                state.checkedPermissions = [
                  ...state.checkedPermissions,
                  permission,
                ];
              });
            });
          } else {
            item.contenttypedetails.map((appitem) => {
              item.appPermissionsChecked = false;
              appitem.permissions.map((permission) => {
                permission.permissionChecked = false;
                state.checkedPermissions = state.checkedPermissions.filter(
                  (permission1) => {
                    if (permission1.id !== permission.id) {
                      return true;
                    }
                    return false;
                  }
                );
              });
            });
          }
        }
        return item;
      });
    },

    checkPermission: (state, action) => {
      if (state.appPermissionsList.length !== 0) {
        state.appPermissionsList.map((item) => {
          if (action.payload.appPermission_id == item.id) {
            item.contenttypedetails.map((appitem) => {
              if (action.payload.contentType_id == appitem.id) {
                appitem.permissions.map((permission) => {
                  if (action.payload.permission_id == permission.id) {
                    permission.permissionChecked =
                      !permission.permissionChecked;
                    if (permission.permissionChecked === true) {
                      item.appPermissionsChecked = true;
                      appitem.contentTypeChecked = true;
                      state.checkedPermissions = [
                        ...state.checkedPermissions,
                        permission,
                      ];
                    } else {
                      state.checkedPermissions =
                        state.checkedPermissions.filter((permission) => {
                          if (permission.id !== action.payload.permission_id) {
                            return true;
                          }
                          return false;
                        });
                    }
                  }
                });
              }
            });
          }
        });
      }
    },
    submitProfile: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    getProfilebyID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    profileByIdSuccessful: (state, action) => {
      var permissionsIds = action.payload.permissions;
      state.appPermissionsList.map((item) => {
        item.contenttypedetails.map((appitem) => {
          appitem.permissions.map((permission) => {
            if (permissionsIds.indexOf(permission.id) !== -1) {
              permission.permissionChecked = true;
              state.checkedPermissions = [
                ...state.checkedPermissions,
                permission,
              ];
              item.appPermissionsChecked = true;
              appitem.contentTypeChecked = true;
            }
          });
        });
      });
      state.profileData = {
        ...action.payload,
        locationtype: action.payload.groupdetails?.locationtype,
        reportingto_id: action.payload.groupdetails?.reportingto,
      };

      // return {
      //   ...state,
      //   loading: false,
      //   profileData: {
      //     ...action.payload,
      //     locationtype: action.payload.groupdetails?.locationtype,
      //     reportingto_id: action.payload.groupdetails?.reportingto,
      //   },
      //   // persmsitonsList: permissionlist1,
      // };
    },

    setProfileParams: (state, action) => {
      return {
        ...state,
        profileParams: action.payload,
      };
    },
    profileDelete: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    clearProfileData: (state, action) => {
      return {
        ...state,
        loading: false,
        profileData: {},
        // profileList: [],
      
        appPermissionsList: [],
        checkedPermissions: [],
       
      };
    },

    clearprofileParams:(state, action) => {
      return{
        ...state,
        loading: true,
        profileParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      }
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getProfileList,
  profileListSuccessful,
  getPermissionsList,
  permissionsListSuccessful,
  InputChangeValue,
  checkAppPermission,
  checkPermission,
  submitProfile,
  clearprofileParams,
  getProfilebyID,
  profileByIdSuccessful,
  setProfileParams,
  profileDelete,
  clearProfileData,
  apiError,
} = manageProfileSlice.actions;

export default manageProfileSlice.reducer;
