import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
  apiError,
  getZones,
  zonesListSuccessful,
  isModelVisible,
} from "./zonesSlice";

// AUTH related methods
import {
  postAdd,
  getParamsList,
  getList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* ZonesGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/zones/", params);
    yield put(zonesListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* AddZones({ payload }) {
  console.log("aadcountry", payload.id);

  try {
    if (payload.id == 0) {
      const response = yield call(postAdd, "/masters/zones/", payload);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Zone Add Successfully.!
              </p>
            </div>
          ),
        });
        yield put(isModelVisible(false));
        yield put(getZones({ page: 1, search: "", page_size: 10 }));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Zone. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/masters/zones/" + payload.id,
        payload
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Zone Edited Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getZones({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Zone. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    console.log(error);
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeleteZone({ payload }) {
  try {
    const response = yield call(postDelete, "/masters/zones/" + payload);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Zone has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getZones({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Zone has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

export function* watchgetZones() {
  yield takeEvery("zones/getZones", ZonesGet);
}
export function* watchZonesAdd() {
  yield takeEvery("zones/zoneAdd", AddZones);
}

export function* watchZonesDelete() {
  yield takeEvery("zones/zoneDelete", DeleteZone);
}

function* ZonesSaga() {
  yield all([fork(watchgetZones), fork(watchZonesAdd), fork(watchZonesDelete)]);
}

export default ZonesSaga;
