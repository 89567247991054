import { createSlice } from "@reduxjs/toolkit";

export const repairAmountSlice = createSlice({
  name: "repairAmount",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    repairAmountList: [],
    model: false,
    repairViewModel: false,
    drawer: false,
    error_msg: null,
    repairTypeImgLooading: false,
    rowdata: {},
    listCount: 0,
    rowDataView: {},
    reapirAmountParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getRepairAmount: (state, action) => {
      if (state.reapirAmountParams.page == 1) {
        return {
          ...state,
          loading: true,
          repairAmountList: [],
          reapirAmountParams: {
            no_of_pages: 0,
            page_size: action.payload.page_size,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    repairAmountListSuccessful: (state, action) => {
      var noofpages = Math.ceil(action.payload.count / state.reapirAmountParams.page_size);
      return {
        ...state,
        listCount: action.payload.count,
        repairAmountList: action.payload.results,
        loading: false,
        reapirAmountParams: {
          ...state.reapirAmountParams,
          no_of_pages: noofpages,
        },
      };
    },
    clearReapirAmountList: (state) => {
      return {
          ...state,
          loading: true,
          repairAmountList: [],
        };
    },
    repairAmountAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    repairAmountAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    getRepairAmountByID: (state) => {
      return {
        ...state,
        loading: false,
        // rowdata: {},
      };
     
    },
    repairAmountByIDSuccessful: (state, action) => {
      return {
        ...state,
        // model: true,
        rowdata: {
          id: action.payload.id,
          amount: action.payload.amount,
          description: action.payload.description,
          purchase_id: action.payload.purchase?.id,
          counter_id: action.payload.counter?.id,
          repair_id: action.payload.repair?.id,
          attachment: action.payload.attachment,
          attachment_id: action.payload.attachment?.id,
        },
        rowDataView: action.payload
      };
    },
    isRepairAmountViewModelVisible: (state, action) => {
      if(action.payload == true){
        return {
          ...state,
          repairViewModel: action.payload,
        };
      }
      else{
        return {
          ...state,
          repairViewModel: action.payload,
          rowdata: {}
        };
      }
     
    },
    repairAmountDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetRepairAmountParams: (state, action) => {
      return {
        ...state,
        reapirAmountParams: action.payload,
      };
    },
    repairAmountApproveStatus: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getRepairTypeImageUpload: (state, action) => {
      return {
        ...state,
        loading: true,
        repairTypeImgLooading: true,
        rowdata: {
          ...state.rowdata,
          [action.payload.imageType]: null,
        },
      };
    },
    repairTypeImageUploadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        repairTypeImgLooading: false,
        rowdata: {
          ...state.rowdata,
          [action.payload.ImageType]: action.payload.response.data,
          [action.payload.ImageType + "_id"]: action.payload.response.data.id,
        },
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getRepairAmount,
  repairAmountListSuccessful,
  repairAmountAdd,
  repairAmountAddSuccessful,
  getRepairAmountByID,
  repairAmountByIDSuccessful,
  repairAmountDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetRepairAmountParams,
  isRepairAmountViewModelVisible,
  repairAmountApproveStatus,
  clearReapirAmountList,
  getRepairTypeImageUpload,
  repairTypeImageUploadSuccessful,
} = repairAmountSlice.actions;

export default repairAmountSlice.reducer;
