import {
  API_ERROR,
  MENU_ITEMS,
  MENU_ITEMS_LIST_SUCCESSFUL,
  SELECT_CHANGEVALUE,
  INPUT_CHANGEVALUE,
  MENU_ITEMS_ADD,
  MENU_ITEMS_ADD_SUCCESSFUL,
  MENU_ITEMS_ROW_DATA,
  MENU_ITEMS_ROW_DATA_SUCCESSFUL,
  MENU_ITEMS_DELETE,
  MENU_ITEMS_SETROWDATA,
  MODEL_OPEN,
  MODEL_CLOSE,
  SET_PARAMS,
} from "./actionTypes";

export const getMenuItems = (data) => {
  return {
    type: MENU_ITEMS,
    payload: { data },
  };
};

export const MenuItemsListSuccessful = (locations) => {
  return {
    type: MENU_ITEMS_LIST_SUCCESSFUL,
    payload: locations,
  };
};
export const SelecteChangeValue = ({ key, label, key2, value }) => {
  return {
    type: SELECT_CHANGEVALUE,
    payload: { key, label, key2, value },
  };
};
export const InputChangeValue = ({ key, value }) => {
  return {
    type: INPUT_CHANGEVALUE,
    payload: { key, value },
  };
};

export const MenuItemAdd = (data) => {
  return {
    type: MENU_ITEMS_ADD,
    payload: { data },
  };
};

export const MenuItemAddSuccessful = (response) => {
  return {
    type: MENU_ITEMS_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const menuItemRowData = (MenuItemID) => {
  return {
    type: MENU_ITEMS_ROW_DATA,
    payload: { MenuItemID },
  };
};

export const MenuItemRowDataSuccessful = (response) => {
  return {
    type: MENU_ITEMS_ROW_DATA_SUCCESSFUL,
    payload: response,
  };
};

export const MenuItemDelete = (MenuItemID) => {
  return {
    type: MENU_ITEMS_DELETE,
    payload: { MenuItemID },
  };
};

export const SetEditData = (data) => {
  return {
    type: MENU_ITEMS_SETROWDATA,
    payload: data,
  };
};

export const model_open = () => {
  return {
    type: MODEL_OPEN,
  };
};

export const model_close = () => {
  return {
    type: MODEL_CLOSE,
  };
};

export const SetParams = (params) => {
  return {
    type: SET_PARAMS,
    payload: params,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
