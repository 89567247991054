import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getPerson,
  personListSuccessful,
  isModelVisible,
  InputChangeValue,
  getcustomerByIDSuccessful,
  SetPersonParams
} from "./personSlice";
import { getMiniVehiclesNonStock, getMiniVehicles } from "../../mini/miniSlice";
import {
  getPurchaseDoc,
  getPurchaseCheckApprove
} from "../../../store/sideMenu/vendors/vendorSlice";
// import {
//   getVehicleSaleDoc,
//   getSalesCheckApprove
// }from "../../../store/sideMenu/vehicleCounterSales/vehicleCounterSalesSlice";
// AUTH related methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  getMiniDistrict,
  getMiniCity,
  getMiniArea,
} from "../../../store/mini/miniSlice";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

import customerData from "./customerData.json"

// function* PersonGet({ payload }) {
//   var params = {};
//   for (const k in payload.params) {
//     if (Object.hasOwnProperty.call(payload.params, k)) {
//       if (k === "currentSort" || k === "sortOrder") {
//         params.ordering = payload.params.sortOrder + payload.params.currentSort;
//       } else {
//         if (payload.params[k] !== "" && k !== "no_of_pages") {
//           params[k] = payload.params[k];
//         }
//       }
//     }
//   }
//   try {
//     const response = yield call(
//       getParamsList,
//       "/masters/person/list",
//       params
//     );
//     yield put(personListSuccessful(response));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* PersonGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if(k == 'start_date' ){
            params[k] = moment(data[k]).format("YYYY-MM-DD")
          }else if( k == 'end_date'){
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD")
          }else{
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    // const response = yield call(
    //   getParamsList,
    //   "/masters/person/list",
    //   params
    // );
    const CustomerData = customerData
    yield put(personListSuccessful(CustomerData));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* aadharnoValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/personaadhar/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "aadharno",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}
function* mobileValidate({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/personmobile/validation/",
      payload
    );
  } catch (error) {
    if (error.response.status == 400) {
      yield put(
        InputChangeValue({
          key: "mobile",
          value: "",
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    } else if (error.response.status == 500) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}
function* AddPerson({ payload }) {
  try {
    if (payload.obj.id == 0) {
      const response = yield call(postAdd, "/masters/person/", payload.obj);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Customer Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getPerson({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
        yield put(
          getMiniVehiclesNonStock({ page: 1, search: "", page_size: 10 })
        );
        yield put(getMiniVehicles({ page: 1, search: "", page_size: 10 }));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Customer. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postEdit,
        "/masters/person/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Customer Edited Successfully.!
              </p>
            </div>
          ),
        });
        if(payload.type === "purchase"){
          yield put(getPurchaseDoc(payload.typeId));
          yield put(getPurchaseCheckApprove({
            purchase_id: payload.typeId,
          }));
        }
        else if(payload.type === "sale"){
          // yield put(getVehicleSaleDoc(payload.typeId));
          // yield put(getSalesCheckApprove({
          //   sale_id: payload.typeId,
          // }));
        }
        else{
          yield put(getPerson({ page: 1, search: "", page_size: 10 }));
        }
        yield put(isModelVisible(false));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit Customer. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DeletePerson({ payload }) {
  var personID = payload;
  try {
    const response = yield call(postDelete, "/masters/person/" + personID);

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Customer has been deleted!
            </p>
          </div>
        ),
      });
      // yield put(SetPersonParams({
    
      //   page: 1,
      //   search: "",
      //   page_size: 10 
      // }));
      yield put(getPerson({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Customer has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* CustomerByID({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/masters/person/" + payload.row.id,
      {}
    );
    yield put(getMiniDistrict({ state: response.state?.id }));
    yield put(getMiniCity({ district: response.district?.id }));
    yield put(getMiniArea({ city: response.city?.id }));
    yield put(getcustomerByIDSuccessful({response, type: payload.type}));
    // yield put(isModelVisible(true));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetPerson() {
  yield takeEvery("person/getPerson", PersonGet);
}
export function* watchCheckAadharnoValidate() {
  yield takeEvery("person/checkAadharnoValidate", aadharnoValidate);
}
export function* watchCheckMobileValidate() {
  yield takeEvery("person/checkMobileValidate", mobileValidate);
}
export function* watchPersonAdd() {
  yield takeEvery("person/personAdd", AddPerson);
}

export function* watchPersonDelete() {
  yield takeEvery("person/personDelete", DeletePerson);
}

export function* watchGetcustomerByID() {
  yield takeEvery("person/getcustomerByID", CustomerByID);
}

function* PersonSaga() {
  yield all([
    fork(watchGetPerson),
    fork(watchCheckAadharnoValidate),
    fork(watchCheckMobileValidate),
    fork(watchPersonAdd),
    fork(watchPersonDelete),
    fork(watchGetcustomerByID),
  ]);
}

export default PersonSaga;
