import axios from "axios";

import { v4 as uuidv4 } from "uuid";
import { browserName } from "react-device-detect";

const BaseURL = "http://144.24.97.26:85"; //dev
const IOBaseURL = "ws://144.24.97.26:5000"; // dev Io

// const BaseURL = "https://api2.wheelsmart.in"; //PRODUCTION
// const IOBaseURL = "wss://io.wheelsmart.in/"; // PRODUCTION Io

const TOKEN_PREFIX = "Bearer  ";
const uuid = uuidv4();

const axiosInstance = axios.create({
  baseURL: BaseURL,
  timeout: 80000,
  headers: {
    Authorization: localStorage.getItem("access_token")
      ? TOKEN_PREFIX + localStorage.getItem("access_token")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

function removeToken() {
  localStorage.clear();
  // window.location.href = "/login/";
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken && refreshToken !== "undefined") {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return new Promise(function (resolve, reject) {
            axiosInstance
              .post(BaseURL + "/users/token/refresh/", {
                refresh: refreshToken,
                device_uuid: uuid,
                device_name: browserName,
                device_type: 3, // web
              })
              .then((response) => {
                localStorage.setItem("access_token", response.data.access);

                axiosInstance.defaults.headers["Authorization"] =
                  TOKEN_PREFIX + response.data.access;
                originalRequest.headers["Authorization"] =
                  TOKEN_PREFIX + response.data.access;
                processQueue(null, response.data.access);
                resolve(axiosInstance(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                // store.dispatch(showMessage({ message: "Expired Token" }));

                // window.location = "/login/";
                removeToken();

                reject(err);
              })
              .then(() => {
                isRefreshing = false;
              });
          });
        } else {
          // window.location.href = "/login/";
          removeToken();
        }
      } else {
        // window.location.href = "/login/";
        removeToken();
      }
    } else if (err.response.status === 403) {
      window.location.href = "/pages/error-page-403";
    } else if (err.response.status === 500) {
      window.location.href = "/pages/error-page-500";
    } else if (err.response.status === 502) {
      window.location.href = "/pages/error-page-502";
    } else if (err.response.status === 503) {
      window.location.href = "/pages/error-page-503";
    }
    return Promise.reject(err);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const originalRequest = error.config;

//     // Prevent infinite loops early
//     if (
//       error.response.status === 401 &&
//       originalRequest.url === BaseURL + "users/token/refresh/"
//     ) {
//       window.location.href = "/login/";
//       return Promise.reject(error);
//     }

//     if (
//       error.response.data.code === "token_not_valid" &&
//       error.response.status === 401 &&
//       error.response.statusText === "Unauthorized"
//     ) {
//       const refreshToken = localStorage.getItem("refresh_token");
//

//       if (refreshToken && refreshToken !== "undefined") {
//         const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));
//
//         // exp date in token is expressed in seconds, while now() returns milliseconds:
//         const now = Math.ceil(Date.now() / 1000);
//

//         if (tokenParts.exp > now) {
//           return axiosInstance
//             .post("users/token/refresh/", { refresh: refreshToken })
//             .then((response) => {
//               localStorage.setItem("access_token", response.data.access);
//               localStorage.setItem("refresh_token", response.data.refresh);

//               axiosInstance.defaults.headers["Authorization"] =
//                 TOKEN_PREFIX + response.data.access;
//               originalRequest.headers["Authorization"] =
//                 TOKEN_PREFIX + response.data.access;

//               return axiosInstance(originalRequest);
//             })
//             .catch((err) => {
//
//             });
//         } else {
//
//           window.location.href = "/login/";
//         }
//       } else {
//
//         window.location.href = "/login/";
//       }
//     }

//     // specific error handling done elsewhere
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
export { BaseURL, IOBaseURL, axiosInstance, TOKEN_PREFIX };
