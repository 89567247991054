import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    vehicleslist: [],
    model: false,
    drawer: false,
    error_msg: null,
    listCount: 0,
    rowdata: {
      id: 0,
    },
    vehiclesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    // vehiclesFilterParams: {
    //   no_of_pages: 0,
    //   page_size: 10,
    //   page: 1,
    //   search: "",
    //   currentSort: "code",
    //   sortOrder: "-",
    // },
  },
  reducers: {
    getVehicles: (state, action) => {
      if(action.payload?.page == 1){
        return {
          ...state,
          loading: true,
          vehicleslist: [],
          // vehiclesParams: {
          //   no_of_pages: 0,
          //   page_size: action.payload.page_size,
          //   page: 1,
          //   search: "",
          //   currentSort: "code",
          //   sortOrder: "-",
          // },
        }
      }
      else {
      return {
        ...state,
        loading: true,
      };
    }
    },
    vehiclesListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.vehiclesParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.count,
        vehicleslist: action.payload.results,
        loading: false,
        vehiclesParams: {
          ...state.vehiclesParams,
          no_of_pages: noofpages,
        },
      };
    },

    // getVehicles: (state, action) => {
    //   if (state.vehiclesParams?.page == 1) {
    //     return {
    //       ...state,
    //       loading: true,
    //       vehicleslist: [],
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    //   }
    // },
    // vehiclesListSuccessful: (state, action) => {
    //   var response = action.payload;
    //   var list = [];
    //   if (state.vehiclesParams?.page == 1) {
    //     list = response.results;
    //   } else {
    //     list = [...state.vehicleslist, ...response.results];
    //   }
    //   // var noofpages = Math.ceil(response.count / state.vehiclesParams?.page_size);
    //   var noofpages = Math.ceil(
    //     action.payload.count / state.vehiclesParams?.page_size
    //   );
    //   return {
    //     ...state,
    //     listCount: action.payload.count,
    //     vehicleslist: list,
    //     loading: false,
    //     vehiclesParams: {
    //       ...state.vehiclesParams,
    //       no_of_pages: noofpages,
    //     },
    //   };
    // },


    
    vehiclesAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    vehiclesAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    vehiclesDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "vehicleType":
          if (action.payload.value == "old") {
            return {
              ...state,
              rowdata: {
                ...state.rowdata,
                vehicleno: null,
                [action.payload.key]: action.payload.value,
              },
            };
          } else {
            return {
              ...state,
              rowdata: {
                ...state.rowdata,
                [action.payload.key]: action.payload.value,
              },
            };
          }
        case "brand_id":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              brandfamily_id: null,
              model_id: null,
              version_id: null,
            },
          }

        case "brandfamily_id":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              model_id: null,
              version_id: null,
            },
          }

        case "model_id":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              version_id: null,
            },
          }

        default:
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
            },
          };
      }
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetVehiclesParams: (state, action) => {
      return {
        ...state,
        vehiclesParams: action.payload,
      };
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {

        case "version__brand":
          return {
            ...state,
            vehiclesParams: {
              ...state.vehiclesParams,
              [action.payload.key]: action.payload.value,
              version__brandfamily: null,
              version__model: null,
            },
          }

        case "version__brandfamily":
          return {
            ...state,
            vehiclesParams: {
              ...state.vehiclesParams,
              [action.payload.key]: action.payload.value,
              version__model: null,
            },
          }
        default:
          return {
            ...state,
            vehiclesParams: {
              ...state.vehiclesParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
    },
    vehicleNumberValidate: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    engineNumberValidate: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    chassisNumberValidate: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getvehicleByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    vehicleByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
          vehicleType:
            action.payload.vehicletrno == action.payload.vehicleno
              ? "new"
              : "old",
          vehicleno: action.payload.vehicleno,
          brand_id: action.payload.version?.brand?.id,
          brandfamily_id: action.payload.version?.brandfamily?.id,
          model_id: action.payload.version?.model?.id,
          vehicletrno: action.payload.vehicletrno,
          year: moment(action.payload?.year, "YYYY"),
          engineno: action.payload.engineno,
          emission_id: action.payload.emission?.id,
          version_id: action.payload.version?.id,
          chassisno: action.payload.chassisno,
        },
      };
    },
    // SetVehiclesFilterParams: (state, action) => {
    //   return {
    //     ...state,
    //     vehiclesFilterParams: action.payload,
    //   };
    // },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getVehicles,
  vehiclesListSuccessful,
  vehiclesAdd,
  vehiclesAddSuccessful,
  // vehiclesEdit,
  vehiclesDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetVehiclesParams,
  isDrawerVisible,
  FilterInputChangeValue,
  vehicleNumberValidate,
  engineNumberValidate,
  chassisNumberValidate,
  getvehicleByID,
  vehicleByIDSuccessful,
  // SetVehiclesFilterParams,
} = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
