import { createSlice } from "@reduxjs/toolkit";

export const companybanksSlice = createSlice({
  name: "companybanks",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    companybankslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    companybanksParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getCompanybanks: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    companybanksListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.companybanksParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        companybankslist: action.payload.response.results,
        loading: false,
        companybanksParams: {
          ...state.companybanksParams,
          no_of_pages: noofpages,
        },
      };
    },
    companybanksAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    companybanksAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    companybanksEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    companybanksEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    companybanksDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetCompanybanksParams: (state, action) => {
      return {
        ...state,
        companybanksParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getCompanybanks,
  companybanksListSuccessful,
  companybanksAdd,
  companybanksAddSuccessful,
  companybanksEdit,
  companybanksEditSuccessful,
  companybanksDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetCompanybanksParams
} = companybanksSlice.actions;

export default companybanksSlice.reducer;
