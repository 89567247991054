import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
export const currentRidesSlice = createSlice({
  name: "currentRides",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    apiError: {},
    transactionData: {},
    listCount: 0,
    saleListCount: 0,
    activeTab: "1",
    screen:{ Type: "none", id: 0},
    purchaseTransactionList: [],
    saleTransactionList: [],
    // purchaseTransactionparams: {
    //   no_of_pages: 0,
    //   page_size: 10,
    //   page: 1,
    //   search: "",
    //   currentSort: "code",
    //   sortOrder: "-",
    // },
    // saleTransactionparams: {
    //   no_of_pages: 0,
    //   page_size: 10,
    //   page: 1,
    //   search: "",
    //   currentSort: "code",
    //   sortOrder: "-",
    // },

    transactionparams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    Purchase_Count: 0,
    Directsalecount: 0,
    FinanceSalecount: 0,
    Ovfsale_Count:0,

  },
  reducers: {
    getPurchaseTransactionList: (state, action) => {
      if (state.transactionparams.page == 1) {
        return {
          ...state,
          loading: true,
          purchaseTransactionList: [],
          transactionparams: {
            ...state.transactionparams,
            // page_size: 10,
            // page: 1,
            ...action.payload 
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    purchaseTransactionListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.transactionparams?.page_size
      );
      console.log(action.payload);
      return {
        ...state,
        listCount: action.payload.response.count,
        purchaseTransactionList:[ ...state.purchaseTransactionList, ...action.payload.response.results],
        loading: false,
        transactionparams: {
          ...state.transactionparams,
          no_of_pages: noofpages,
        },
      };
    },
    setTransactionParams: (state, action) => {
      return {
        ...state,
        transactionparams: action.payload,
      };
    },


    

    

    getSaleTransactionList: (state, action) => {
      if (state.transactionparams.page == 1) {
        return {
          ...state,
          loading: true,
          saleTransactionList: [],
          transactionparams: {
            ...state.transactionparams,
            // page_size: 10,
            // page: 1,
           ...action.payload 
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    saleTransactionListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.transactionparams?.page_size
      );
      console.log(action.payload);
      return {
        ...state,
        saleListCount: action.payload.response.count,
        saleTransactionList: [...state.saleTransactionList, ...action.payload.response.results],
        loading: false,
        transactionparams: {
          ...state.transactionparams,
          no_of_pages: noofpages,
        },
      };
    },
    // setSaleTransactionParams: (state, action) => {
    //   return {
    //     ...state,
    //     saleTransactionparams: action.payload,
    //   };
    // },
    transactionActiveTab: (state, action) => {
      return {
        ...state,
        loading: true,
        activeTab: action.payload,
      };
    },
    transactionApprovalScreen: (state, action) => {
      return {
        ...state,
        loading: false,
        screen: action.payload,
      };
    },
    transationCount: (state, action) => {
        return {
          ...state,
          loading: true,
        };
    },
    transationCountListSuccessful: (state, action) => {
      return {
        ...state,
        Purchase_Count: action.payload.response.Purchase_Count,
        Directsalecount: action.payload.response.Directsalecount,
        FinanceSalecount: action.payload.response.FinanceSalecount,
        Ovfsale_Count: action.payload.response.Ovfsale_Count,
        loading: false,
      };
    },
    clearPurchaseTransactionList: (state, action) => {
      return {
        ...state,
        loading: true,
        purchaseTransactionList: [],
        
      };
  },

  clearSaleTransactionList: (state, action) => {
    return {
      ...state,
      loading: true,
      saleTransactionList: [],
    };
  },
    clearTransactionParams: (state, action) => {
      return {
        ...state,
        loading: true,
        transactionparams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      }
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
        return {
          ...state,
          transactionparams: {
            ...state.transactionparams,
            [action.payload.key]: action.payload.value,
          },
        };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getPurchaseTransactionList,
  purchaseTransactionListSuccessful,
  setTransactionParams,
  getSaleTransactionList,
  saleTransactionListSuccessful,
  // setSaleTransactionParams,
  transactionActiveTab,
  transactionApprovalScreen,
  clearPurchaseTransactionList,
  clearSaleTransactionList,
  transationCount,
  transationCountListSuccessful,
  clearTransactionParams,
  isDrawerVisible,
  FilterInputChangeValue,
  apiError,
} = currentRidesSlice.actions;

export default currentRidesSlice.reducer;
