import { createSlice } from "@reduxjs/toolkit";

export const vehicletypesSlice = createSlice({
  name: "vehicletypes",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    vehicletypeslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    vehicletypesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getVehicletypes: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    vehicletypesListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.vehicletypesParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        vehicletypeslist: action.payload.response.results,
        loading: false,
        vehicletypesParams: {
          ...state.vehicletypesParams,
          no_of_pages: noofpages,
        },
      };
    },
    vehicletypesAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    vehicletypesAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    vehicletypesEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    vehicletypesEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    vehicletypesDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetVehicletypesParams: (state, action) => {
      return {
        ...state,
        vehicletypesParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getVehicletypes,
  vehicletypesListSuccessful,
  vehicletypesAdd,
  vehicletypesAddSuccessful,
  vehicletypesEdit,
  vehicletypesEditSuccessful,
  vehicletypesDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetVehicletypesParams
} = vehicletypesSlice.actions;

export default vehicletypesSlice.reducer;
