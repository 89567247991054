import { createSlice } from "@reduxjs/toolkit";

export const prioritySlice = createSlice({
  name: "priority",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    prioritynew: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    priorityParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getPrioritys: (state) => {
      console.log("getPrioritys");
      return {
        ...state,
        loading: true,
      };
    },
    priorityListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.priorityParams.page_size
      );
      console.log(action.payload.response.results);
      return {
        ...state,
        listCount: action.payload.response.count,
        prioritynew: action.payload.response.results,
        loading: false,
        priorityParams: {
          ...state.priorityParams,
          no_of_pages: noofpages,
        },
      };
    },
    priorityAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    priorityAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    priorityEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    priorityEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    priorityDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetPrioritysParams: (state, action) => {
      return {
        ...state,
        priorityParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getPrioritys,
  priorityListSuccessful,
  priorityAdd,
  priorityAddSuccessful,
  priorityEdit,
  priorityEditSuccessful,
  priorityDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetPrioritysParams
} = prioritySlice.actions;

export default prioritySlice.reducer;
