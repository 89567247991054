import { createSlice } from "@reduxjs/toolkit";

export const financiersSlice = createSlice({
  name: "financiers",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    financierslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    financiersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getFinanciers: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    financiersListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.financiersParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        financierslist: action.payload.response.results,
        loading: false,
        financiersParams: {
          ...state.financiersParams,
          no_of_pages: noofpages,
        },
      };
    },
    financierAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    financiersEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          mobile: action.payload.mobile,
          aadharno: action.payload.aadharno,
          companyname: action.payload.companyname,
          gstno: action.payload.gstno,
          bank_id: action.payload.bank.id,
          accono: action.payload.accono,
          ifsccode: action.payload.ifsccode,
          companyaddress: action.payload.companyaddress,
        },
      };
    },

    financierDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    SetFinanciersParams: (state, action) => {
      return {
        ...state,
        financiersParams: action.payload,
      };
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getFinanciers,
  financiersListSuccessful,
  financierAdd,
  financiersEdit,
  financierDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetFinanciersParams
} = financiersSlice.actions;

export default financiersSlice.reducer;
