import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/mobilelogin",
    component: lazy(() =>
      import("../../view/pages/authentication/login/mobilenumber-login")
    ),
    layout: "FullLayout",
  },
  {
    path: "/login",
    component: lazy(() =>
      import("../../view/pages/authentication/login/username-login")
    ),
    layout: "FullLayout",
  },
  // Master pages
  {
    path: "/Master",
    component: lazy(() => import("../../view/pages/MastersDashboard")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Locations",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/Locations")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/country",
    component: lazy(() => import("../../view/pages/MastersDashboard/country")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/state",
    component: lazy(() => import("../../view/pages/MastersDashboard/state")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/District",
    component: lazy(() => import("../../view/pages/MastersDashboard/district")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/City",
    component: lazy(() => import("../../view/pages/MastersDashboard/city")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Area",
    component: lazy(() => import("../../view/pages/MastersDashboard/area")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/zone",
    component: lazy(() => import("../../view/pages/MastersDashboard/zones")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/firm",
    component: lazy(() => import("../../view/pages/MastersDashboard/firm")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/brand",
    component: lazy(() => import("../../view/pages/MastersDashboard/brands")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/emissionnorms",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/emissionnorms")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/occupations",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/occupations")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/person",
    component: lazy(() => import("../../view/pages/MastersDashboard/person")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/colors",
    component: lazy(() => import("../../view/pages/MastersDashboard/colors")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/banks",
    component: lazy(() => import("../../view/pages/MastersDashboard/banks")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/priority",
    component: lazy(() => import("../../view/pages/MastersDashboard/priority")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/companybanks",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/companybanks")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/divisions",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/divisions")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/clusters",
    component: lazy(() => import("../../view/pages/MastersDashboard/clusters")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/mechanics",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/mechanics")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/dealers",
    component: lazy(() => import("../../view/pages/MastersDashboard/dealer")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Counters",
    component: lazy(() => import("../../view/pages/MastersDashboard/counters")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Formulas",
    component: lazy(() => import("../../view/pages/MastersDashboard/formulas")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Departments",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/departments")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/brandfamily",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/brandfamily")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/models",
    component: lazy(() => import("../../view/pages/MastersDashboard/models")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/addressproof",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/addressproof")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/emidates",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/emidates")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/insurance",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/insurance")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/leavereasons",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/leavereasons")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/versionfeatures",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/versionfeatures")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/rejectreasons",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/rejectreasons")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/vehicletypes",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/vehicletypes")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/showrooms",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/showrooms")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/percentageinterests",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/percentageinterests")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/tenures",
    component: lazy(() => import("../../view/pages/MastersDashboard/tenures")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/financiers",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/financiers")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/versions",
    component: lazy(() => import("../../view/pages/MastersDashboard/versions")),
    layout: "VerticalLayout",
  },
  
  {
    path: "/Masters/Re-Estimation",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/reestimationamount")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Repair-Amount",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/repairamount")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/Repair-Types",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/repairTypes")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/fv-questions",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/fvQuestions")
    ),
    layout: "VerticalLayout",
  },

  {
    path: "/pages/settings/",
    component: lazy(() => import("../../view/pages/settings")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/settings/user-details/:id",
    component: lazy(() => import("../../view/pages/settings/ManageUsers/userDetails")),
    layout: "VerticalLayout",
  },
  // {
  //   path: "/pages/settings/new-profile/:id",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/manage-profile",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/new-users/:id",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/managers",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/location-manager",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },

  // {
  //   path: "/pages/settings/approvals",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/app-settings",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/manage-company",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/screen-settings",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/backup-database",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/import-csv",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/export-csv",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/pages/settings/reset-database",
  //   component: lazy(() => import("../../view/pages/settings")),
  //   layout: "VerticalLayout",
  // },

  // SideBar Pages
  
  
  // errors
  {
    path: "/pages/error-page-404",
    component: lazy(() => import("../../view/pages/error/404/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-403",
    component: lazy(() => import("../../view/pages/error/403/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-500",
    component: lazy(() => import("../../view/pages/error/500/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-502",
    component: lazy(() => import("../../view/pages/error/502/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-503",
    component: lazy(() => import("../../view/pages/error/503/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/enquiry",
    component: lazy(() => import("../../view/pages/Dashboard/enquiry")),
    layout: "VerticalLayout",
  },
  
  // Profile View
  {
    path: "/pages/userProfile/personel-information",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/userProfile/activity",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/userProfile/password-change",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/userProfile/user-devices",
    component: lazy(() => import("../../view/pages/userProfile")),
    layout: "VerticalLayout",
  },
  // REPORTS
  {
    path: "/pages/reports/",
    component: lazy(() => import("../../view/pages/Reports/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/DailyAttendance",
    component: lazy(() =>
      import("../../view/pages/Reports/DailyAttendance/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Sale",
    component: lazy(() => import("../../view/pages/Reports/Sale/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Purchase",
    component: lazy(() => import("../../view/pages/Reports/Purchase/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Stock",
    component: lazy(() => import("../../view/pages/Reports/Stock/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/RepairVechicle",
    component: lazy(() =>
      import("../../view/pages/Reports/RepairVechicle/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/TempraroryTransfer",
    component: lazy(() =>
      import("../../view/pages/Reports/TempraroryTransfer/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Enquiry",
    component: lazy(() => import("../../view/pages/Reports/Enquiry/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/SaleQuotation",
    component: lazy(() =>
      import("../../view/pages/Reports/SaleQuotation/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/RepairAmount",
    component: lazy(() => import("../../view/pages/Reports/RepairAmount/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/RE-Amount",
    component: lazy(() => import("../../view/pages/Reports/REAmount/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Insurance",
    component: lazy(() => import("../../view/pages/Reports/Insurance/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/Leave",
    component: lazy(() => import("../../view/pages/Reports/Leave/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/stock-transfer-out",
    component: lazy(() => import("../../view/pages/Reports/StockTransferOut/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/stock-transfer-in",
    component: lazy(() => import("../../view/pages/Reports/StockTransferIn/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/Masters/vendoradd/:id",
    component: lazy(() =>
      import("../../view/pages/MastersDashboard/dealer/vendorAdd")
    ),
    layout: "VerticalLayout",
  },








// Side Menu

{
  path: "/pages/vendor",
  component: lazy(() => import("../../view/pages/Dashboard/vendors")),
  layout: "VerticalLayout",
},
{
  path: "/pages/vendor_add/:id",
  component: lazy(() =>
    import("../../view/pages/Dashboard/vendors/vendorAdd")
  ),
  layout: "VerticalLayout",
},
{
  path: "/pages/vendor-view/:id",
  component: lazy(() =>
    import("../../view/pages/Dashboard/vendors/VendorView")
  ),
  layout: "VerticalLayout",
},

{
  path: "/pages/bookings",
  component: lazy(() => import("../../view/pages/Dashboard/bookings")),
  layout: "VerticalLayout",
},
{
  path: "/pages/booking_add/:id",
  component: lazy(() =>
    import("../../view/pages/Dashboard/bookings/bookingAdd")
  ),
  layout: "VerticalLayout",
},
{
  path: "/pages/booking-view/:id",
  component: lazy(() =>
    import("../../view/pages/Dashboard/bookings/bookingView")
  ),
  layout: "VerticalLayout",
},

{
  path: "/pages/vehicle",
  component: lazy(() => import("../../view/pages/Dashboard/vehicles")),
  layout: "VerticalLayout",
},
{
  path: "/pages/vehicle_add/:id",
  component: lazy(() =>
    import("../../view/pages/Dashboard/vehicles/vehicleAdd")
  ),
  layout: "VerticalLayout",
},
{
  path: "/pages/vehicle-view/:id",
  component: lazy(() =>
    import("../../view/pages/Dashboard/vehicles/VehicleView")
  ),
  layout: "VerticalLayout",
},

{
  path: "/pages/customer",
  component: lazy(() => import("../../view/pages/Dashboard/customers")),
  layout: "VerticalLayout",
},
{
  path: "/pages/vendor_dashboard",
  component: lazy(() => import("../../view/pages/Dashboard/vendorDashboard")),
  layout: "VerticalLayout",
},

{
  path: "/pages/vehicle_dashboard",
  component: lazy(() => import("../../view/pages/Dashboard/vehicleDashboard")),
  layout: "VerticalLayout",
},
  {
    path: "/pages/current-rides",
    component: lazy(() =>
      import("../../view/pages/Dashboard/currentRides")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/all-rides",
    component: lazy(() =>
      import("../../view/pages/Dashboard/allRides")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/future-rides",
    component: lazy(() =>
      import("../../view/pages/Dashboard/futureRides")
    ),
    layout: "VerticalLayout",
  },
  // {
  //   path: "/Masters/payments",
  //   component: lazy(() => import("../../view/pages/Dashboard/payments")),
  //   layout: "VerticalLayout",
  // },

  {
    path: "/pages/receipts",
    component: lazy(() =>
      import("../../view/pages/Dashboard/accounting/receipt")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/receipt-view/:id",
    component: lazy(() =>
      import(
        "../../view/pages/Dashboard/accounting/receipt/receiptView"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/add-receipt/:id",
    component: lazy(() =>
      import("../../view/pages/Dashboard/accounting/receipt/receiptAdd")
    ),
    layout: "VerticalLayout",
  },


  {
    path: "/pages/payments",
    component: lazy(() =>
      import("../../view/pages/Dashboard/accounting/payments")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/payment-view/:id",
    component: lazy(() =>
      import(
        "../../view/pages/Dashboard/accounting/payments/paymentView"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/add-payment/:id",
    component: lazy(() =>
      import("../../view/pages/Dashboard/accounting/payments/paymentAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/transaction",
    component: lazy(() => import("../../view/pages/Dashboard/transaction")),
    layout: "VerticalLayout",
  },
];

export default PagesRoutes;
