import React, { useState, createElement, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col } from "antd";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";

import logoSmall from "../../../assets/images/logo/logo-small.svg";

import MenuFooter from "./footer";
import MenuItem from "./item";
import MenuMobile from "./mobile";
import { sidebarEnable } from "../../../store/customise/customiseSlice";
const { Sider } = Layout;
function Sidebar(props) {
  const { visible, setVisible } = props;

  // Redux
  const customise = useSelector((state) => state.customise);

  // Collapsed
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (customise.sidebarCollapsed) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [customise]);

  // Location
  const location = useLocation();
  const { pathname } = location;

  // Mobile Sidebar
  const onClose = () => {};

  // Menu
  function toggle() {
    setCollapsed(!collapsed);
  }

  const trigger = createElement(collapsed ? RiMenuUnfoldLine : RiMenuFoldLine, {
    className: "trigger",
    onClick: toggle,
  });

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={props.sidebarMenuEnable}
      width={256}
      className="hp-sidebar hp-bg-color-black-0 hp-bg-color-dark-100"
    >
      <MenuItem onClose={onClose} />

      {/* <MenuFooter onClose={onClose} collapsed={props.sidebarMenuEnable} /> */}

      <MenuMobile
        onClose={() => props.sidebarEnable(false)}
        visible={props.sidebarMenuEnable}
      />
    </Sider>
  );
}

function mapStateToProps({ customise }) {
  return {
    sidebarMenuEnable: customise.sidebarMenuEnable,
    sidebarCollapseButton: customise.sidebarCollapseButton,
  };
}

export default connect(mapStateToProps, {
  sidebarEnable,
})(Sidebar);
