import { createSlice } from "@reduxjs/toolkit";

export const percentageinterestsSlice = createSlice({
  name: "percentageinterests",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    percentageinterestslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    percentageinterestsParams: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getPercentageinterests: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    percentageinterestsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.percentageinterestsParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        percentageinterestslist: action.payload.response.results,
        loading: false,
        percentageinterestsParams: {
          ...state.percentageinterestsParams,
          no_of_pages: noofpages,
        },
      };
    },
    percentageinterestsAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    percentageinterestsAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    percentageinterestsEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: action.payload,
      };
    },
    percentageinterestsEditSuccessful: (state) => {
      return { ...state, loading: false, rowdata: {} };
    },
    percentageinterestsDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetPercentageinterestsParams: (state, action) => {
      return {
        ...state,
        percentageinterestsParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getPercentageinterests,
  percentageinterestsListSuccessful,
  percentageinterestsAdd,
  percentageinterestsAddSuccessful,
  percentageinterestsEdit,
  percentageinterestsEditSuccessful,
  percentageinterestsDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetPercentageinterestsParams
} = percentageinterestsSlice.actions;

export default percentageinterestsSlice.reducer;
