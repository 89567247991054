import React from "react";

import { Card, Row, Col } from "antd";

import data from "./data";

export default function Home() {
  const Menus = data;

  const HomeMenus = Menus.map((menu, index) => (
    <React.Fragment key={index}>
      {
        <Col xl={6} md={6} span={12}>
          <Card
            onClick={() => {}}
            className="hp-text-center hp-icon-card hp-border-color-dark-80"
          >
            <i className={"hp-text-color-dark-0 ri-2x ri-" + menu + "-line"} />
            <p className="hp-badge-text">
              <b>COMPANY</b>
            </p>
            <p className="hp-badge-text">Manage Company</p>
          </Card>
        </Col>
      }
    </React.Fragment>
  ));

  return (
    <Row className=" hp-text-center">
      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100" align="middle" justify="center">
          <Col>
            {/* <Row gutter={[32, 32]}>{HomeMenus}</Row> */}
            <h2>Welcome to Ride-O-Now</h2>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
