import {
  GET_MASTERS_MENU,
  // ADD_MENU,
  // PUT_MENU,
  // DELETE_MENU,
  API_ERROR,
  GET_MASTERS_MENU_SUCCESSFUL,
} from "./actionTypes";

export const getMastersMenu = (M_Menu, history) => {
  return {
    type: GET_MASTERS_MENU,
    payload: { M_Menu, history },
  };
};

export const getMastersMenuSuccessful = (Masters_Menu) => {
  return {
    type: GET_MASTERS_MENU_SUCCESSFUL,
    payload: { Masters_Menu },
  };
};

// export const add_menu = (menu) => {
//   return {
//     type: ADD_MENU,
//     payload: menu,
//   };
// };

// export const put_menu = (menu, history) => {
//   return {
//     type: PUT_MENU,
//     payload: { menu, history },
//   };
// };

// export const delete_menu = (menu) => {
//   return {
//     type: DELETE_MENU,
//     payload: menu,
//   };
// };

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
