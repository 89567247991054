import { createSlice } from "@reduxjs/toolkit";

export const modelsSlice = createSlice({
  name: "models",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    modelslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    listCount: 0,
    modelsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getModels: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
      };
    },
    modelsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.ModelData.count / state.modelsParams.page_size
      );

      return {
        ...state,
        listCount: action.payload.ModelData.count,
        modelslist: action.payload.ModelData.results,
        loading: false,
        modelsParams: {
          ...state.modelsParams,
          no_of_pages: noofpages,
        },
      };
    },
    modelAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    modelsEdit: (state, action) => {
      var colorsIds = [];
      colorsIds = action.payload.color.map((color) => {
        return color.id;
      });
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          brand_id: action.payload.brand.id,
          brandfamily_id: action.payload.brandfamily.id,
          color_ids: colorsIds,
        },
      };
    },
    modelDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    SetModelsParams: (state, action) => {
      return {
        ...state,
        modelsParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getModels,
  modelsListSuccessful,
  modelAdd,
  modelsEdit,
  modelDelete,
  InputChangeValue,
  apiError,
  SetModelsParams,
  isModelVisible,
} = modelsSlice.actions;

export default modelsSlice.reducer;
