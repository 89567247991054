import { createSlice } from "@reduxjs/toolkit";

export const mechanicsSlice = createSlice({
  name: "mechanics",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    mechanicslist: [],
    model: false,
    error_msg: null,
    rowdata: {},
    params: {
      no_of_pages: 0,
      listCount: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getMechanics: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    mechanicsListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.params.page_size
      );

      return {
        ...state,
        listCount: action.payload.response.count,
        mechanicslist: action.payload.response.results,
        loading: false,
        params: {
          ...state.params,
          no_of_pages: noofpages,
        },
      };
    },
    mechanicAdd: (state) => {
      return {
        ...state,
        loading: false,
      };
    },

    mechanicsEdit: (state, action) => {
      return {
        ...state,
        model: true,
        rowdata: {
          id: action.payload.id,
          name: action.payload.name,
          bank_id: action.payload.bank.id,
          mobile: action.payload.mobile,
          aadharno: action.payload.aadharno,
          accono: action.payload.accono,
          ifsccode: action.payload.ifsccode,
          address: action.payload.address,
        },
      };
    },

    mechanicDelete: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        rowdata: {
          ...state.rowdata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getMechanics,
  mechanicsListSuccessful,
  mechanicAdd,

  mechanicsEdit,

  mechanicDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
} = mechanicsSlice.actions;

export default mechanicsSlice.reducer;
